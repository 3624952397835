<template>
  <b-container style="max-width: 1400px">
    <DashboardHeader />
    <div class="banner-headline text-center">
      <div class="banner-headline-content">
        <h4 class="text-white text-center">TATA TERTIB PESERTA DIDIK</h4>
        <div class="reg-container mt-4">
          <HeaderUserInfo />
          <br />
          <div class="dashboard-title">
            <h4>DASHBOARD</h4>
          </div>
          <br />
          <FlowMenuRegister />
          <div class="dashboard-content">
            <div class="text-right">
              <b-link @click="showpdfview">
                <b-icon
                  icon="printer"
                  alt="Printer"
                  font-scale="3"
                ></b-icon> </b-link
              >{{ " " }}
              <b-link :to="{ path: '/dashboard' }">
                <b-icon icon="x" variant="danger" font-scale="4"></b-icon>
              </b-link>
            </div>

            <b-overlay
              :show="loaderShow"
              spinner-variant="primary"
              spinner-type="grow"
              spinner-small
              rounded="sm"
            >
              <b-card :aria-hidden="loaderShow ? 'true' : null">
                <div class="text-center">
                  <b-button variant="dark" size="lg" @click="showpdfview"
                    >Download Tata Tertib Peserta Didik</b-button
                  >
                </div>
              </b-card>
            </b-overlay>
          </div>
        </div>
      </div>
    </div>

    <HomeFooter />
  </b-container>
</template>

<script>
import axios from "axios";
import DashboardHeader from "@/components/DashboardHeader.vue";
import HomeFooter from "@/components/HomeFooter.vue";
import HeaderUserInfo from "@/components/HeaderUserInfo.vue";
import FlowMenuRegister from "@/components/FlowMenuRegister.vue";

export default {
  name: "SchoolRuleInfo",
  components: {
    DashboardHeader,
    HomeFooter,
    HeaderUserInfo,
    FlowMenuRegister,
  },
  data() {
    return {
      basePath: axios.defaults.baseURL,
      loaderShow: false,
      urlpdf: '',
    };
  },
  created() {
    this.UrlPDF();
  },
  methods: {
    async UrlPDF() {
      try {
        const response = await axios({
          method: "get",
          url: "api/settingapps/schoolruleinfo/read",
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        });
        let resdata = response.data;
        this.urlpdf = resdata['fileurl'];
      } catch (error) {
        console.log(error);
      }
    },
    showpdfview() {
      //this.loaderShow = true;
      let randomNumber = Date.now();
      window.open(this.urlpdf+"?rand="+randomNumber);    
    },
  },
};
</script>

<style>
</style>