<template>
  <div class="container text-center mt-5 text-muted">
    <i>please wait for redirection...</i><br />
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "GoToChild",
  data() {
    return {
      token: "",
      auth: "",
      payload: "",
      fullname: "",
      id_user: "",
      id_reg: "",
      noreg: "",
      format_birthdate: "",
    };
  },
  created() {
    this.checkParam();
    this.getDefaultVar();
    this.switchToken();
  },
  methods: {
    checkParam() {
      this.id_reg = this.$route.params.id_reg;
      this.noreg = this.$route.params.noreg;
      this.format_birthdate = this.$route.params.format_birthdate;
      if (
        this.id_reg === undefined ||
        this.noreg === undefined ||
        this.format_birthdate === undefined
      ) {
        this.$router.push({ path: "/reglist" });
      }
    },
    getDefaultVar() {
      this.token = localStorage.getItem("token");
      this.auth = localStorage.getItem("auth");
      this.payload = localStorage.getItem("payload");
      this.fullname = this.auth.fullname;
      this.id_user = this.auth.id_user;
    },
    async switchToken() {
      try {
        const response = await axios({
          method: "post",
          url: "/api/switchtoken/" + this.id_reg,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          data: {
            username: this.noreg,
            password: this.format_birthdate,
          },
        });
        if (response.data.access_token !== false) {
          // save to VuexParent
          let dataVuexParent = {
            token: this.token,
            auth: this.auth,
            payload: this.payload,
          };
          this.$store.commit("parent", dataVuexParent);

          // save to localStorage
          let dataAuth = JSON.stringify(response.data.auth);
          let dataPayload = JSON.stringify(response.data.payload);
          localStorage.setItem("token", response.data.access_token);
          localStorage.setItem("auth", dataAuth);
          localStorage.setItem("payload", dataPayload);
          // save to Vuex
          let dataVuex = {
            token: response.data.access_token,
            auth: dataAuth,
            payload: dataPayload,
          };
          this.$store.commit("login", dataVuex);

          // redirect
          this.$router.push({ path: "/dashboard" });
        } else {
          this.$router.push({ path: "/reglist" });
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style>
</style>