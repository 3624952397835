<template>
  <div class="text-dark text-left">
    <b-card no-body>
      <b-tabs v-model="tabIndex" content-class="mt-3" card fill>
        <b-tab title="FORMULIR PENDAFTARAN SISWA INTERNAL">
          <h4 class="text-center mt-2">FORMULIR PENDAFTARAN INTERNAL</h4>
          <div class="reg-container" style="margin-top:-20px;">
            <RegInfoInternal />
            <br />
            <RegScheduleInternal />
            <br />
            <RegFormInternal />
          </div>
          </b-tab
        >
        <b-tab title="FORMULIR PENDAFTARAN SISWA EKSTERNAL">
          <h4 class="text-center mt-2">FORMULIR PENDAFTARAN EKSTERNAL</h4>
          <div class="reg-container" style="margin-top:-20px;">
            <RegInfoExternal />
            <br />
            <RegScheduleEksternal />
            <br />
            <RegFormExternal />
          </div>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import RegInfoInternal from "@/components/RegInfoInternal.vue";
import RegInfoExternal from "@/components/RegInfoExternal.vue";
import RegScheduleEksternal from "@/components/RegScheduleEksternal.vue";
import RegScheduleInternal from "@/components/RegScheduleInternal.vue";
import RegFormExternal from "@/components/RegFormExternal.vue";
import RegFormInternal from "@/components/RegFormInternal.vue";

export default {
  name: "RegTab",
  components: {
    RegInfoInternal,
    RegInfoExternal,
    RegScheduleEksternal,
    RegFormExternal,
    RegFormInternal,
    RegScheduleInternal,
  },
  data() {
    return {
      tabIndex: 0,
      param_id_reg: '',
    }
  },
  created() {
    this.checkInternalRegWithRouteParam()
  },
  methods: {
    checkInternalRegWithRouteParam() {
      //console.log(this.$route.params.id_reg);
      if(this.$route.params.id_reg !== undefined) {
        this.param_id_reg = this.$route.params.id_reg;
        this.tabIndex = 0;
        //console.log('yesss undefined');
      }else
      if(this.$route.params.tabindex !== undefined) {
        this.tabIndex = 1;
      }
    }
  }
};
</script>

<style>
</style>