<template>
  <div>
    <b-container style="max-width: 1400px">
      <DashboardHeader />
      <div class="banner-headline text-center">
        <div class="banner-headline-content">
          <h4 class="text-white text-center">PENDAFTARAN SISWA</h4>
          <div class="reg-container mt-4">
            <HeaderUserInfo v-if="rerender_headeruserinfo"/>
            <br />
            <div class="dashboard-title">
              <h4>DASHBOARD</h4>
            </div>
            <br />
            <FlowMenuRegister />
            <br />
            <div>
              <!--<UploadDocumentProfileStudent />-->
              <div class="reginfo2">
                <div class="infotitle">
                  <b-container>
                    <b-row>
                      <b-col cols="1"> </b-col>
                      <b-col cols="11" class="text-left">
                        <h4>UPLOAD DOKUMEN PENDUKUNG</h4>
                      </b-col>
                    </b-row>
                  </b-container>
                </div>
                <div class="infocontent2">
                  <div class="text-left">
                    <b-row>
                      <b-col md="6">
                        <GenUploadRegDoc
                          label="Upload Photo"
                          formatinfo="Upload file dokumen berformat JPG/PNG"
                          sizeinfo="Maksimum ukuran 2MB."
                          type="PHOTO"
                          accept="image/jpeg, image/png, image/gif"
                          @rerender_headeruserinfo="rerender_headeruserinfo = $event"
                        ></GenUploadRegDoc
                      ></b-col>
                      <b-col md="6">
                        <GenUploadRegDoc
                          label="Upload Akta Kelahiran"
                          formatinfo="Upload file dokumen berformat JPG/PNG"
                          sizeinfo="Maksimum ukuran 2MB."
                          type="AKTA"
                          accept="image/jpeg, image/png, image/gif"
                          @rerender_headeruserinfo="rerender_headeruserinfo = $event"
                        ></GenUploadRegDoc>
                      </b-col>
                    </b-row>

                    <hr />

                    <b-row>
                      <b-col md="6">
                        <GenUploadRegDoc
                          label="Upload Dokumen Kartu Keluarga"
                          formatinfo="Upload file dokumen berformat JPG/PNG"
                          sizeinfo="Maksimum ukuran 2MB."
                          type="KK"
                          accept="image/jpeg, image/png, image/gif"
                          @rerender_headeruserinfo="rerender_headeruserinfo = $event"
                        ></GenUploadRegDoc
                      ></b-col>
                      <b-col md="6">
                        <GenUploadRegDoc
                          label="Upload Surat Pernyataan OTM"
                          formatinfo="Upload file dokumen berformat JPG/PNG"
                          sizeinfo="Maksimum ukuran 2MB."
                          type="OTM"
                          accept="image/jpeg, image/png, image/gif"
                          @rerender_headeruserinfo="rerender_headeruserinfo = $event"
                        ></GenUploadRegDoc>
                      </b-col>
                    </b-row>

                    <hr />

                    <b-row>
                      <b-col md="6">
                        <GenUploadRegDoc
                          label="Upload Dokumen RAPORT"
                          formatinfo="Upload file dokumen berformat PDF"
                          sizeinfo="Maksimum ukuran 2MB."
                          type="RAPORT"
                          accept="application/pdf"
                          @rerender_headeruserinfo="rerender_headeruserinfo = $event"
                        ></GenUploadRegDoc
                      ></b-col>
                      <b-col md="6"> </b-col>
                    </b-row>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <HomeFooter />
    </b-container>
  </div>
</template>

<script>
//import UploadDocumentProfileStudent from "@/components/UploadDocumentProfileStudent.vue";
import GenUploadRegDoc from "@/components/Generate/GenUploadRegDoc.vue";
import DashboardHeader from "@/components/DashboardHeader.vue";
import HomeFooter from "@/components/HomeFooter.vue";
import FlowMenuRegister from "@/components/FlowMenuRegister.vue";
import HeaderUserInfo from "@/components/HeaderUserInfo.vue";

export default {
  name: "UploadDocument",
  components: {
    //UploadDocumentProfileStudent,
    GenUploadRegDoc,
    DashboardHeader,
    HomeFooter,
    FlowMenuRegister,
    HeaderUserInfo,
  },
  data() {
    return {
      rerender_headeruserinfo: true,
    };
  },
};
</script>

<style>
</style>