<template>
  <b-container style="max-width: 1400px">
    <DashboardHeader />
    <RegisterForm />
    <HomeFooter />
  </b-container>
</template>

<script>
// @ is an alias to /src
import RegisterForm from "@/components/RegisterForm.vue";
import DashboardHeader from "@/components/DashboardHeader.vue";
import HomeFooter from "@/components/HomeFooter.vue";

export default {
  name: "Register",
  components: {
    RegisterForm,
    DashboardHeader,
    HomeFooter,
  },
  data() {
    return {
      token: "",
    };
  },
  created() {
    this.checkAuth();
  },
  methods: {
    checkAuth() {
      this.token = localStorage.getItem("token");
      if (this.token != "") {
        this.$router.push({ path: "/parentguard" });
      }
    },
  },
};
</script>