<template>
  <div>
    <b-container style="max-width: 1400px">
      <DashboardHeader />
      <div class="banner-headline text-center">
        <div class="banner-headline-content">
          <h4 class="text-white text-center">PENDAFTARAN SISWA</h4>
          <div class="reg-container mt-4">
            <HeaderUserInfo v-if="rerender_headeruserinfo" />
            <br />
            <div class="dashboard-title">
              <h4>DASHBOARD</h4>
            </div>
            <br />
            <FlowMenuRegister />
            <div class="image-path-flow">
              <img
                alt="Flow Register"
                src="../assets/img/flow-04.jpg"
                class="img-fluid"
              />
            </div>
            <div class="reginfo2">
              <div class="infotitle">
                <b-container>
                  <b-row>
                    <b-col cols="1"> </b-col>
                    <b-col cols="11" class="text-left">
                      <h4>KETERANGAN ORANG TUA</h4>
                    </b-col>
                  </b-row>
                </b-container>
              </div>
            </div>
            <ParentProfileStudentTables
              @rerender_headeruserinfo="rerender_headeruserinfo = $event"
            />
          </div>
        </div>
      </div>

      <HomeFooter />
    </b-container>
  </div>
</template>

<script>
import ParentProfileStudentTables from "@/components/ParentProfileStudentTables.vue";
import DashboardHeader from "@/components/DashboardHeader.vue";
import HomeFooter from "@/components/HomeFooter.vue";
import FlowMenuRegister from "@/components/FlowMenuRegister.vue";
import HeaderUserInfo from "@/components/HeaderUserInfo.vue";

export default {
  name: "ParentInfo",
  components: {
    ParentProfileStudentTables,
    DashboardHeader,
    HomeFooter,
    FlowMenuRegister,
    HeaderUserInfo,
  },
  data() {
    return {
      rerender_headeruserinfo: true,
    };
  },
  mounted() {
    window.scrollTo(600, 600);
  },
};
</script>

<style>
</style>