<template>
  <b-container style="max-width: 1400px">
    <DashboardHeader />
    <div class="banner-headline text-center">
      <div class="banner-headline-content">
        <h4 class="text-white text-center">PERNYATAAN ORANG TUA</h4>
        <div class="reg-container mt-4">
          <HeaderUserInfo />
          <br />
          <div class="dashboard-title">
            <h4>DASHBOARD</h4>
          </div>
          <br />
          <FlowMenuRegister />
          <div class="dashboard-content">
            <div class="text-right">
              <b-link @click="showpdfview" v-if="showbuttonpdf">
                <b-icon
                  icon="printer"
                  alt="Printer"
                  font-scale="3"
                ></b-icon> </b-link
              >{{ " " }}
              <b-link :to="{ path: '/dashboard' }">
                <b-icon icon="x" variant="danger" font-scale="4"></b-icon>
              </b-link>
            </div>
            <div v-if="showbuttonpdf" class="mt-5">
              <b-overlay
                :show="loaderShow"
                spinner-variant="primary"
                spinner-type="grow"
                spinner-small
                rounded="sm"
              >
                <b-card :aria-hidden="loaderShow ? 'true' : null">
                  <div class="text-center">
                    <b-button variant="dark" size="lg" @click="showpdfview"
                      >Download Surat Pernyataan Orang Tua</b-button
                    >
                  </div>
                </b-card>
              </b-overlay>

              
            </div>
            <div v-if="showwarning">
              <h4 class="mb-4 text-center">
                Mohon lengkapi data Siswa terlebih dahulu.
              </h4>
            </div>
          </div>
        </div>
      </div>
    </div>

    <HomeFooter />
  </b-container>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import DashboardHeader from "@/components/DashboardHeader.vue";
import HomeFooter from "@/components/HomeFooter.vue";
import HeaderUserInfo from "@/components/HeaderUserInfo.vue";
import FlowMenuRegister from "@/components/FlowMenuRegister.vue";

export default {
  name: "ParentAgreementInfo",
  components: {
    DashboardHeader,
    HomeFooter,
    HeaderUserInfo,
    FlowMenuRegister,
  },
  data() {
    return {
      loaderShow: false,
      showbuttonpdf: false,
      showwarning: false,
      basePath: axios.defaults.baseURL,
      urlparentagreement: "",
      id_reg: "",
      pdfsrc: null,
    };
  },
  created() {
    this.getDefaultVar();
    this.CheckMandatoryData();
  },
  methods: {
    getDefaultVar() {
      this.token = localStorage.getItem("token");
      this.auth = JSON.parse(localStorage.getItem("auth"));
      this.fullname = this.auth.fullname;
      this.id_reg = this.auth.id_reg;
      let random = Math.random().toString(16).slice(2);
      this.urlparentagreement =
        this.basePath + "/api/reg/parentagreement/" + this.id_reg + "/" + random;
    },
    async CheckMandatoryData() {
      try {
        const response = await axios({
          method: "get",
          url: "/api/checkvalidmandatorydata/" + this.id_reg,
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        });
        if (response.data.valid == false) {
          this.showbuttonpdf = false;
          this.showwarning = true;

          Swal.fire({
            title: "Perhatian!",
            html: "Untuk mendownload data, mohon lengkapi data Calon Siswa terlebih dahulu!",
            icon: "info",
          });
        } else {
          this.showbuttonpdf = true;
          this.showwarning = false;
          //console.log(this.urlparentagreement);
          //this.showPDFParentAgreeement();
        }
      } catch (error) {
        console.log(error);
      }
    },
    showpdfview() {
      //this.loaderShow = true;
      //window.location.href = this.urlparentagreement;
      window.open(this.urlparentagreement);
      //window.open(this.urlparentagreement, '_blank');
    },
    async showPDFParentAgreeement() {
      try {
        let random = Math.random().toString(16).slice(2);
        const response = await axios({
          method: "get",
          url: "api/reg/parentagreement/" + this.id_reg + "/" + random,
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          responseType: "blob"
        });
        console.log(response);
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style>
</style>