<template>
  <b-container style="max-width: 1400px">
    <DashboardHeader />
    <div class="banner-headline text-center">
      <div class="text-center mb-4 pt-5">
        <h3>Lock user!</h3>
      </div>
      <p class="lead text-center">Session expired, silahkan login dahulu.</p>
      <div class="text-center pb-5">
        <!--<base-button type="primary" :to="{ path: '/login' }" class="mt-2">Go to Login</base-button>-->
        <router-link to="/login" class="btn btn-danger">
          <span class="nav-link-inner--text">Go to Login</span>
        </router-link>
      </div>
    </div>
    <HomeFooter />
  </b-container>
</template>

<script>
import DashboardHeader from "@/components/DashboardHeader.vue";
import HomeFooter from "@/components/HomeFooter.vue";

export default {
  name: "Lock",
  components: {
    DashboardHeader,
    HomeFooter,
  },
  created() {
    setTimeout(() => {
      this.$router.push({ path: "/logout" });
    }, 1000);
  },
};
</script>

<style>
</style>