<template>
  <b-container style="max-width: 1400px">
    <DashboardHeader />
    <div class="banner-headline">
      <div class="banner-headline-content">
        <div class="reg-container mt-4">
          <div
            class="color-tosca text-center"
            style="
              max-width: 700px;
              margin: auto;
              padding-top: 50px;
              padding-bottom: 50px;
            "
          >
            <img
              alt="Instrument"
              src="../assets/img/notify-hp.jpg"
              width="200"
              class="img-fluid"
            />
            <br /><br />
            <h4>
              <b>
                Data Pendaftaran Sudah Kami Record Silahkan Cek WhatsApp Untuk
                Informasi Biaya Pendaftaran.</b
              >
            </h4>
            <br /><br />
            <b-button variant="primary" :to="{path:'/reglist'}">Kembali ke Dashboard</b-button>
          </div>
        </div>
      </div>
    </div>
    <HomeFooter />
  </b-container>
</template>

<script>
import axios from "axios";
import DashboardHeader from "@/components/DashboardHeader.vue";
import HomeFooter from "@/components/HomeFooter.vue";

export default {
  name: "SuccessSubmitDataSendNotify",
  components: {
    DashboardHeader,
    HomeFooter,
  },
  data() {
    return {
      id_reg: localStorage.getItem("id_reg"),
      notifies: [],
      loading_notifies: "processing..",
    };
  },
  created() {
    this.sendNotify();
  },
  methods: {
    async sendNotify() {
      try {
        const response = await axios.get(
          "/api/sendnotifyafterreg/" + this.id_reg
        );
        this.notifies = response.data;
        //console.log(this.notifys);

        this.loading_notifies = "";
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style>
</style>