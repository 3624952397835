<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import axios from "axios";

// @ is an alias to /src
export default {
  name: "App",
  data() {
    return {
      token: localStorage.getItem("token"),
      themesapp: [],
    };
  },
  async beforeCreate() {
    // check route, if login =>refresh ThemesApp
    let curRoute = this.$router.currentRoute.path;
    if (curRoute == "/login") {
      try {
        const response = await axios.get("/api/settingapp/getthemeapp", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        // save to vuex
        let dataThemesApp = response.data;
        this.$store.commit("themesapp_remove");
        this.$store.commit("themesapp_store", dataThemesApp);

        this.themesapp = dataThemesApp;
      } catch (error) {
        console.log(error);
      }
    } else {
      let themesapp = this.$store.getters.themesapp;
      if (themesapp == "") {
        // get GetThemesApp from server
        // redirect to logout
        this.$router.push({ path: "/logout" });
      } else {
        this.themesapp = themesapp;
      }
    }
  },
};
</script>

<style>
.banner-headline {
  background-color: #00a18e;
  padding-top: 50px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 40px;
  color: white;
  border-radius: 60px 60px 0px 0px;
  margin-top: -80px;
}
.banner-headline h1 {
  font-size: 100px;
}
.banner-headline h2 {
  font-size: 50px;
}
.banner-headline h3 {
  font-size: 40px;
}
.banner-headline-content {
  padding: 50px;
}
.color-yellow {
  color: #e4f925;
}
.login-form {
  margin: auto;
  max-width: 400px;
  padding: 20px;
  border-radius: 40px;
  background-color: #ffffff;
  margin-top: 50px;
  margin-bottom: 50px;
}
.login-form-bottom-text {
  margin-top: -20px;
  text-align: left;
  color: #333333;
}
.register-form {
  text-align: left;
  color: #333333;
  margin: auto;
  max-width: 500px;
  padding: 40px;
  border-radius: 40px;
  margin-top: 20px;
  margin-bottom: 50px;
}
.reg-container {
  background-color: #ffffff;
  padding: 50px;
  border-radius: 40px;
}
.reginfo {
  background-color: #03a892;
  color: white;
  padding: 40px;
  border-radius: 20px;
  font-size: 18px;
}
.reginfo2 {
  background-color: #03a892;
  color: white;
  font-size: 18px;
}
.reginfo2 .infotitle {
  background-color: #0c8d7b;
  padding: 20px;
  color: white;
  font-size: 20px;
}
.reginfo2 .infocontent {
  background-color: #03a892;
  padding: 20px;
  color: white;
  font-size: 16px;
}
.reginfo2 .infocontent2 {
  background-color: #ffffff;
  padding: 20px;
  color: #333333;
  font-size: 16px;
}
.thead-reginfo {
  background-color: #ffffff;
  color: #0c8d7b;
}
.tbody-reginfo {
  color: white;
}
.color-tosca {
  color: #0c8d7b;
}
.dashboard-content {
  color: #333333;
  text-align: left;
}
.infogreen {
  border: 1px solid #0c8d7b;
  border-left: 20px solid #0c8d7b;
  padding: 20px;
  border-radius: 10px;
}
.dashboard-title {
  background-color: #0c8d7b;
  padding: 20px;
  border-radius: 20px;
}
.td-button-log {
  width: 180px;
}
.td-logo-izada {
  text-align: right;
}
.form-input-label-right {
  text-align: right;
}
.logo-bottom-left {
  text-align: left;
}
.banner-login {
  padding: 40px;
}
.showemptytableinfo {
  padding: 50px 20px 50px 20px;
  text-align: center;
}
.image-path-flow {
  margin-bottom:20px;
}

@media only screen and (max-width: 1000px) {
  .banner-headline-content {
    padding: 0px;
  }
}
@media only screen and (max-width: 800px) {
  .reg-container {
    padding: 40px 20px 40px 20px;
  }
  .login-ppdb-info {
    margin-top: -100px;
  }
  .logo {
    text-align: center;
  }
  .td-button-log {
    width: 50%;
  }
  .td-logo-izada {
    text-align: left;
  }
  .banner-headline-content {
    padding: 0px;
  }
  .form-input-label-right {
    text-align: left;
  }
  .logo-bottom-left {
    text-align: center;
  }
}
@media only screen and (max-width: 500px) {
  .banner-login {
    padding: 10px;
  }
  .banner-headline {
    padding: 20px 10px 20px 10px;
  }
  .reg-container {
    padding: 30px 10px 30px 10px;
  }
  .reginfo2 .infocontent2 {
    padding: 10px;
  }
  .login-form {
    padding: 30px 15px 30px 15px;
  }
  .reginfo {
    padding: 10px 10px 10px 10px;
  }
  .img-icon-info img {
    display: none;
  }
  .image-path-flow {
    display:none;
  }
}

/*
.sm-center {
  margin:auto;
  text-align:center;
}
*/
</style>