import axios from "axios";
import store from "../store";
import router from '../router'

export default function setup() {
  /*
  axios.interceptors.request.use(
    function (config) {
      const token = store.getters.token;
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    function (error) {
      return Promise.reject(error);
    }
  );
  */

  // intercepting responses
  axios.interceptors.response.use(
    function (response) {
      let curRoute = router.currentRoute.path;
      let logged = store.getters.logged;
      if((curRoute != '/login') && (curRoute != '/logout') && (curRoute != '/register') && (curRoute != '/recovery')) {
        console.log('Login: '+logged)
        
        if(logged == false) {
          router.push({ path: "/login" });
        }
        
      }
    
    
    
      // Do something with response data
      //console.log('interceptors '+response.status);
      // if logged false redirect to login
      
      return response;
    },
    function (error) {
      // Do something with response error
      if (error.response.status === 401) {
        console.log("Unauthorized Request");
        let token = store.getters.token;
        let auth = JSON.parse(store.getters.auth);
        let id_user = auth.id_user;
        console.log(id_user);
        console.log(token);
        // refresh token
        /*
        try {
          const response = axios({
            method: "get",
            url: "/api/refresh/"+id_user,
            headers: { Authorization: "Bearer " + token },
          });
  
          //let status = response.data.status;
          console.log(response.data.status);
          let new_token = response.data.access_token;
          let new_dataAuth = JSON.stringify(response.data.auth);
          // save to localStorage
          localStorage.setItem("token", new_token);
          localStorage.setItem("auth",new_dataAuth);
          // save to Vuex
          let dataVuex = {
            'token': new_token,
            'auth': new_dataAuth,
          };
          store.commit('login',dataVuex)   
          console.log('new_token => '+new_token)       
  
        } catch (error) {
          console.log(error)
        }
        */        
      }
      return Promise.reject(error);
    }
  );
}
