<template>
  <b-container style="max-width: 1400px">
    <DashboardHeader />
    <div class="banner-headline banner-login text-center bgoverlay">
      <b-row>
        <b-col lg="5" md="5" sm="12" xs="12">
          <LoginForm />
        </b-col>
        <b-col lg="7" md="7" sm="12" xs="12">
          <div class="text-left">
            <br /><br />
            <div class="login-ppdb-info">
              <h4 class="text-center text-dark mt-5">
                {{ themesapp.ppdb_title }}<br />
                <small>{{ themesapp.ppdb_subtitle }}</small>
              </h4>
              <br />
              <b-card no-body>
                <b-tabs
                  card
                  fill
                  active-nav-item-class="font-weight-bold text-uppercase text-dark"
                >
                  <b-tab
                    title="JADWAL INTERNAL"
                    title-link-class="text-dark"
                    active
                  >
                    <RegScheduleInternalStyle02 />
                  </b-tab>
                  <b-tab title="JADWAL EKSTERNAL" title-link-class="text-dark">
                    <RegScheduleEksternalStyle02 />
                  </b-tab>
                </b-tabs>
              </b-card>
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
    <HomeFooter />
  </b-container>
</template>

<script>
// @ is an alias to /src
import LoginForm from "@/components/LoginForm.vue";
import DashboardHeader from "@/components/DashboardHeader.vue";
import HomeFooter from "@/components/HomeFooter.vue";
import RegScheduleEksternalStyle02 from "@/components/RegScheduleEksternalStyle02.vue";
import RegScheduleInternalStyle02 from "@/components/RegScheduleInternalStyle02.vue";

export default {
  name: "Login",
  components: {
    LoginForm,
    DashboardHeader,
    HomeFooter,
    RegScheduleEksternalStyle02,
    RegScheduleInternalStyle02,
  },
  data() {
    return {
      themesapp: [],
    };
  },
  created() {
    this.defaultVar();
  },
  methods: {
    defaultVar() {
      this.themesapp = this.$store.getters.themesapp;
    },
  },
};
</script>

<style scoped>
.bgoverlay {
  /*background: linear-gradient(0deg, rgba(0, 161, 142, 0.3), rgba(0, 161, 142, 0.3)), url('../assets/img/students-knowing-right-answer-min.jpg');*/
  background: linear-gradient(
      0deg,
      rgba(0, 161, 142, 0.3),
      rgba(0, 161, 142, 0.3)
    ),
    url("../assets/img/bg-login02.jpg");
  background-size: cover;
  background-position: center right;
}
</style>