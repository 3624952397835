import Vue from "vue";
import Vuex from "vuex";
import { mapGetters } from "vuex";
import createPersistedState from 'vuex-persistedstate';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    parent: {
      logged: false,
      token: "",
      auth: "",
      payload: "",
    },
    session: {
      logged: false,
      token: "",
      auth: "",
      payload: "",
    },
    refresh_headeruserinfo: false,
    themesapp: [],
  },
  plugins: [createPersistedState()],
  getters: {
    // could use only this getter and use it for both token and logged
    session: (state) => state.session,
    parent: (state) => state.parent,

    // or could have both getters separated
    logged: (state) => state.session.logged,
    token: (state) => state.session.token,
    auth: (state) => state.session.auth,
    payload: (state) => state.session.payload,

    refresh_headeruserinfo: (state) => state.refresh_headeruserinfo,
    themesapp: (state) => state.themesapp,
  },
  mutations: {
    login(state, dataVuex) {
      state.session.logged = true;
      state.session.token = dataVuex.token;
      state.session.auth = dataVuex.auth;
      state.session.payload = dataVuex.payload;
    },
    logout(state) {
      state.session.logged = false;
      state.session.token = '';
      state.session.auth = '';
      state.session.payload = '';

      state.parent.logged = false;
      state.parent.token = '';
      state.parent.auth = '';
      state.parent.payload = '';
    },
    parent(state, dataVuex) {
      state.parent.logged = true;
      state.parent.token = dataVuex.token;
      state.parent.auth = dataVuex.auth;
      state.parent.payload = dataVuex.payload;
    },
    
    auth(state, dataVuex) {
      state.session.auth = dataVuex.auth;
    },
    
    true_refresh_headeruserinfo(state) {
      state.refresh_headeruserinfo = true;
    },
    false_refresh_headeruserinfo(state) {
      state.refresh_headeruserinfo = false;
    },
    themesapp_store(state, dataThemesApp) {
      state.themesapp = dataThemesApp;
    },
    themesapp_remove(state) {
      state.themesapp = [];
    }
  },
  actions: {},
  modules: {},
  computed: {
    ...mapGetters(["session","parent","logged", "token", "auth","payload","themesapp"]),
  },
});
