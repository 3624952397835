<template>
  <div class="login-form shadow">
    <b-overlay
      :show="loaderShow"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      rounded="sm"
    >
      <b-card
        title="Card with overlay"
        :aria-hidden="loaderShow ? 'true' : null"
      >
        <b-row>
          <b-col class="text-right">
            <img
              alt="Vue logo"
              src="../assets/img/logo-sekolah-an-nisaa.png"
              width="50"
            />
          </b-col>
          <b-col class="text-left">
            <img alt="Vue logo" src="../assets/img/logo-izada.jpg" width="70" />
          </b-col>
        </b-row>
        <br />
        <b class="text-success" style="font-size: 20px">PSB AN-NISSA IZADA</b>
        <div v-if="claimnotfound">
          <div class="text-dark">
            <br /><br />
            Maaf, data tidak ditemukan
            <br /><br /><br /><br />
            <div class="login-form-bottom-text">
              <h5>
                Sudah ingat akun?<br />
                <small
                  >Login klik
                  <b-link :to="{ path: '/login' }">disini</b-link>.</small
                >
              </h5>
            </div>
          </div>
        </div>
        <div v-if="claim">
          <div class="mt-3 text-dark">
            <b>{{ reqinput }}</b
            ><br />
            <i
              >Sistem akan mengirimkan <b>Kode OTP</b> sebagai validasi nomor
              Handphone yg terdaftar dibawah ini.</i
            >
            <hr />
            <div class="text-left">
              <div v-if="claimrandomnumberform">
                <form @submit.prevent="handleSubmit">
                  <p style="text-align: center">
                    Cek pesan Whatsapp di nomor <b>{{ selected }}</b
                    ><br />
                    <input type="hidden" v-model="form.id_recovery" />
                  </p>

                  <div class="form-group">
                    <label class="text-dark"><b>Kode OTP</b></label>
                    <input
                      v-model="form.randomnumber"
                      type="number"
                      class="form-control form-control-lg"
                      placeholder="Masukkan Kode OTP"
                      required
                    />
                  </div>

                  <div class="form-group">
                    <label class="text-dark"><b>Password Baru</b></label>
                    <input
                      v-model="form.newpassword"
                      type="text"
                      class="form-control form-control-lg"
                      placeholder="Masukkan Password Baru"
                      required
                    />
                  </div>

                  <b-button pill block variant="info" type="submit" size="lg"
                    >Submit</b-button
                  ><br />
                </form>
              </div>
              <div v-if="cellphonelist">
                <b-form-group
                  label="Pilih nomor HP dibawah ini:"
                  v-slot="{ ariaDescribedby }"
                >
                  <div
                    v-for="recoveryclaim in recoveryclaims"
                    v-bind:key="recoveryclaim.id"
                  >
                    <b-form-radio
                      v-model="selected"
                      :aria-describedby="ariaDescribedby"
                      name="some-radios"
                      :value="recoveryclaim.cellphone"
                      ><small
                        ><b
                          >{{ recoveryclaim.cellphone_hide }} ({{
                            recoveryclaim.fullname_hide
                          }})</b
                        ></small
                      ></b-form-radio
                    >
                  </div>
                  <br />
                  <b-button
                    pill
                    block
                    variant="info"
                    type="button"
                    @click="SendRandomNumber"
                    size="lg"
                    >Kirim Angka Acak</b-button
                  >
                </b-form-group>
              </div>
            </div>
          </div>
          <br />
          <div class="login-form-bottom-text">
            <h5>
              Sudah ingat akun?<br />
              <small
                >Login klik
                <b-link :to="{ path: '/login' }">disini</b-link>.</small
              >
            </h5>
          </div>
        </div>
        <div v-if="recoveryform">
          <div class="mt-3">
            <div v-if="recovery1">
              <div class="form-group">
                <label class="text-dark"
                  ><b>Masukkan Email yang pernah didaftarkan</b></label
                >
                <input
                  v-model="form.email"
                  type="email"
                  class="form-control form-control-lg"
                  placeholder="Masukkan Email Anda"
                  required
                />
              </div>
            </div>
            <div v-if="recovery2">
              <div class="form-group">
                <label class="text-dark"
                  ><b>Masukkan NIS/NOREG siswa Anda</b></label
                >
                <input
                  v-model="form.usercode"
                  type="text"
                  class="form-control form-control-lg"
                  placeholder="Masukkan NIS/NOREG Siswa"
                  required
                />
              </div>
            </div>
            <b-button
              pill
              block
              variant="success"
              type="button"
              @click="handleRecovery"
              size="lg"
              >{{ isLoading }}</b-button
            >
            <br />
            <pre class="text-danger">{{ isError }}</pre>
          </div>
          <div class="login-form-bottom-text">
            <h5>
              Sudah punya akun?<br />
              <small
                >Login klik
                <b-link :to="{ path: '/login' }">disini</b-link>.</small
              ><br />
              <small>
                <b-link @click="SwitchRecovery()"
                  >Gunakan cara Recovery lain</b-link
                >.</small
              >
            </h5>
          </div>
        </div>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";

export default {
  name: "RecoveryForm",
  data() {
    return {
      urlrecovery: "/api/recovery",
      urlstore: "/api/recovery/store",
      urlrestore: "/api/recovery/restore",
      isLoading: "Recovery",
      isError: "",
      loaderShow: false,
      recoveryform: true,
      recovery1: true,
      recovery2: false,
      claim: false,
      id_user: "",
      reqtype: "",
      reqinput: "",
      recoveryclaims: [],
      cellphonelist: false,
      claimrandomnumberform: false,
      selected: "",
      claimnotfound: false,
      i: 1,
      form: {
        id_recovery: "",
        newpassword: "",
        randomnumber: "",
      },
    };
  },
  methods: {
    SwitchRecovery() {
      this.loaderShow = true;
      setTimeout(() => {
        this.i++;
        if (this.i % 2 == 0) {
          this.recovery2 = true;
          this.recovery1 = false;
        } else {
          this.recovery2 = false;
          this.recovery1 = true;
        }

        this.loaderShow = false;
      }, 1000);
    },
    async handleRecovery() {
      try {
        this.loaderShow = true;
        let reqtypeinit = "";
        let reqinputinit = "";

        if (this.i % 2 == 0) {
          reqtypeinit = "recovery2";
          reqinputinit = this.form.usercode;
        } else {
          reqtypeinit = "recovery1";
          reqinputinit = this.form.email;
        }

        const response = await axios({
          method: "post",
          url: this.urlrecovery,
          data: {
            reqtype: reqtypeinit,
            reqinput: reqinputinit,
          },
        });
        //console.log(response.data);
        // set claim var
        this.reqtype = response.data.reqtype;
        this.reqinput = response.data.reqinput;
        this.recoveryclaims = response.data.data;
        if (this.recoveryclaims.length == 0) {
          this.claimnotfound = true;
          this.cellphonelist = false;
          this.claim = false;
        } else {
          this.claimnotfound = false;
          this.cellphonelist = true;
          this.claim = true;
          this.reqtype = response.data.reqtype;
          this.reqinput = response.data.reqinput;
          this.id_user = response.data.id_user;
        }

        this.loaderShow = false;
        this.recoveryform = false;
      } catch (error) {
        console.log(error);
        this.loaderShow = false;
      }
    },
    async SendRandomNumber() {
      this.loaderShow = true;
      if (this.selected == "") {
        this.loaderShow = false;
      } else {
        try {
          const response = await axios({
            method: "post",
            url: this.urlstore,
            data: {
              id_user: this.id_user,
              reqtype: this.reqtype,
              reqinput: this.reqinput,
              cellphone: this.selected,
            },
          });
          this.form.id_recovery = response.data.id_recovery;
          console.log(response.data);

          this.cellphonelist = false;
          this.claimrandomnumberform = true;
          this.loaderShow = false;
        } catch (error) {
          console.log(error);
          this.loaderShow = false;
        }
      }
    },

    async handleSubmit() {
      this.loaderShow = true;
      try {
        const response = await axios({
          method: "post",
          url: this.urlrestore,
          data: this.form,
        });
        if (response.data.recovery == true) {
          Swal.fire({
            title: "Good Job!",
            text: "Password berhasil di perbaharui, silahkan login kembali.",
            icon: "success",
          });
          // redirect login
          this.$router.push({ path: "/login" });
        } else {
          Swal.fire({
            title: "Warning!",
            text: "Kode OTP tidak cocok.",
            icon: "info",
          });
        }

        this.loaderShow = false;
      } catch (error) {
        console.log(error);
        this.loaderShow = false;
      }
    },
  },
};
</script>

<style>
</style>