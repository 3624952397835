<template>
  <div class="reginfo2">
    <div class="infocontent">
      <div class="table-responsive">
        <table class="table">
          <thead class="thead-reginfo">
            <tr>
              <th scope="col">Unit</th>
              <th scope="col">Waktu</th>
            </tr>
          </thead>
          <tbody class="tbody-reginfo">
            <tr v-for="(post, index) in posts" :key="post.id">
              <td :data-index="index + 1">{{ post.desc }}<br /><small>({{ post.ta_desc }})</small></td>
              <td>
                Siswa {{ post.type }}<br />({{ post.display_start_reg }} s/d
                {{ post.display_end_reg }})
              </td>
            </tr>
          </tbody>
        </table>
        <div class="text-center p-5" v-if="displayemptyppdb"><i>PPDB belum dibuka.</i></div>
        <div v-if="loadingInfoRegInt" class="mt-3">
          <b-skeleton animation="fade" width="85%"></b-skeleton>
          <b-skeleton animation="fade" width="55%"></b-skeleton>
          <b-skeleton animation="fade" width="70%"></b-skeleton>
          <b-skeleton animation="fade" width="85%"></b-skeleton>
          <b-skeleton animation="fade" width="55%"></b-skeleton>
          <b-skeleton animation="fade" width="70%"></b-skeleton>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "RegScheduleInternalStyle02",
  data() {
    return {
      posts: [],
      loadingInfoRegInt: false,
      displayemptyppdb: false,
    };
  },
  created() {
    this.getPosts();
  },
  methods: {
    async getPosts() {
      this.loadingInfoRegInt = true;
      try {
        const response = await axios.get("/api/ppdb/internal");
        this.posts = response.data;
        if (this.posts == "") {
          this.displayemptyppdb = true;
        } else {
          this.displayemptyppdb = false;
        }
        //console.log(this.posts);
        this.loadingInfoRegInt = false;
      } catch (error) {
        console.log(error);
        this.loadingInfoRegInt = false;
      }
    },
  },
};
</script>

<style>
</style>