<template>
  <div>
    <b-container style="max-width: 1400px">
      <DashboardHeader />
      <div class="banner-headline text-center">
        <div class="banner-headline-content">
          <h4 class="text-white text-center">
            SELAMAT DATANG, <i>{{ fullname }}</i>
            <small style="font-size: 16px"
              > | 
              <b-button size="sm" variant="dark" :to="{ path: '/parentprofile' }" class="text-white"
                >Update My Profile</b-button
              ></small
            ><br />
            <small>TABEL SISWA TERDAFTAR</small>
          </h4>
          <div class="reg-container mt-4">
            <RegListsTable />
          </div>
        </div>
      </div>
      <HomeFooter />
    </b-container>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import DashboardHeader from "@/components/DashboardHeader.vue";
import HomeFooter from "@/components/HomeFooter.vue";
import RegListsTable from "@/components/RegListsTable.vue";

export default {
  name: "RegList",
  components: {
    DashboardHeader,
    HomeFooter,
    RegListsTable,
  },
  data() {
    return {
      token: localStorage.getItem("token"),
      auth: localStorage.getItem("auth"),
      fullname: "",
      id_user: "",
      regs: "",
    };
  },
  created() {
    this.getDefaultVar();
    this.checkCredentialParentData();
  },
  methods: {
    getDefaultVar() {
      this.token = localStorage.getItem("token");
      this.auth = JSON.parse(localStorage.getItem("auth"));
      this.fullname = this.auth.fullname;
      this.id_user = this.auth.id_user;
    },
    async checkCredentialParentData() {
      try {
        const response = await axios.get("/api/check_credential_parentguard", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        if (response.data.status_credential == 'not complete') {
          Swal.fire(
            "Warning!",
            "Untuk melanjutkan, mohon lengkapi data profile terlebih dahulu.",
            "warning"
          );

          setTimeout(() => {
            // rediret to update my profile
            this.$router.push({ path: "/parentprofile" });
          }, 2000);
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style>
</style>