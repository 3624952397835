<template>
  <div class="container text-center mt-5 text-muted">
    <i>please wait for redirection...</i><br />
    <small
      ><i class="text-danger">{{ emptyToken }}</i></small
    >
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Logout",
  data() {
    return {
      emptyToken: "",
    };
  },
  async created() {
    try {
      const response = await axios.get("/api/logout", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });

      console.log(response);
      // destroy token
      localStorage.setItem("token", "");
      localStorage.setItem("auth", "");
      localStorage.setItem("payload", "");
      // clear all localStorage
      localStorage.clear();
      // default vuex
      this.$store.commit('logout');
      // redirect
      this.$router.push({ path: "/login" });
    } catch (error) {
      console.log(error);
      //this.$router.push({'path':'/login'});
      this.emptyToken = error;
      setTimeout(() => {
        this.$router.push({ path: "/login" });
      }, 1000);
    }
  },
};
</script>

<style scoped>
</style>