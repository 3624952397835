<template>
  <div class="tokeninfo">
      <p class="text-secondary"><i>Sorry!. Token is empty, please <a href="/login">Login</a> first.</i></p>
  </div>
</template>

<script>
export default {
    name: "EmptyToken"
}
</script>

<style scoped>
.tokeninfo {
    width:500px;
    margin:auto;
    margin-top:200px;
    text-align:center;
}
</style>