<template>
  <div class="reginfo2">
    <div class="infotitle">
      <b-container>
        <b-row>
          <b-col cols="1">
            <b-icon icon="calendar3" style="width: 50px; height: 50px"></b-icon>
          </b-col>
          <b-col cols="11" class="text-left">
            <h4>Jadwal Pendaftaran Siswa EKSTERNAL</h4>
          </b-col>
        </b-row>
      </b-container>
    </div>

    <div class="infocontent">
      <div class="table-responsive">
        <table class="table">
          <thead class="thead-reginfo">
            <tr>
              <th scope="col">Unit</th>
              <th scope="col">Waktu</th>
            </tr>
          </thead>
          <tbody class="tbody-reginfo">
            <tr v-for="(post, index) in posts" :key="post.id">
              <td :data-index="index + 1">{{ post.desc }}<br /><small>({{ post.ta_desc }})</small></td>
              <td>Siswa {{ post.type }}<br />({{ post.display_start_reg }} s/d {{ post.display_end_reg }})</td>
            </tr>
          </tbody>
        </table>
        <div class="text-center p-5" v-if="displayemptyppdb"><i>PPDB belum dibuka.</i></div>
          <div v-if="loadingInfoReg" class="mt-3">
              <b-skeleton animation="fade" width="85%"></b-skeleton>
              <b-skeleton animation="fade" width="55%"></b-skeleton>
              <b-skeleton animation="fade" width="70%"></b-skeleton>
              <b-skeleton animation="fade" width="85%"></b-skeleton>
              <b-skeleton animation="fade" width="55%"></b-skeleton>
              <b-skeleton animation="fade" width="70%"></b-skeleton>
          </div>

      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "RegScheduleEksternal",
  data() {
    return {
      posts: [],
      loadingInfoReg: false,
      displayemptyppdb: false,
    };
  },  
  created() {
    this.getPosts();
  }, 
  methods: {
    async getPosts() {
        this.loadingInfoReg = true;

      try {
        const response = await axios.get("/api/ppdb/external");
        this.posts = response.data;
        if (this.posts == "") {
          this.displayemptyppdb = true;
        } else {
          this.displayemptyppdb = false;
        }
        //console.log(this.posts);

        this.loadingInfoReg = false;
      } catch (error) {
        console.log(error);
        this.loadingInfoReg = false;
      }
    },
  }, 
};
</script>

<style>
</style>