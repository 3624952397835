<template>
  <div>
    <b-container style="max-width: 1400px">
      <DashboardHeader />
      <div class="banner-headline text-center">
        <div class="banner-headline-content">
          <h4 class="text-white text-center">PENDAFTARAN SISWA</h4>
          <div class="reg-container mt-4">
            <HeaderUserInfo v-if="rerender_headeruserinfo" />
            <br />
            <div class="dashboard-title">
              <h4>DASHBOARD</h4>
            </div>
            <br />
            <FlowMenuRegister />
            <div class="image-path-flow">
              <img
                alt="Flow Register"
                src="../assets/img/flow-01.jpg"
                class="img-fluid"
              />
            </div>
            <div>
              <MainProfileStudent @rerender_headeruserinfo="rerender_headeruserinfo = $event" />
            </div>
          </div>
        </div>
      </div>

      <HomeFooter />
    </b-container>
  </div>
</template>

<script>
import MainProfileStudent from "@/components/MainProfileStudent.vue";
import DashboardHeader from "@/components/DashboardHeader.vue";
import HomeFooter from "@/components/HomeFooter.vue";
import FlowMenuRegister from "@/components/FlowMenuRegister.vue";
import HeaderUserInfo from "@/components/HeaderUserInfo.vue";

export default {
  name: "Dashboard",
  components: {
    MainProfileStudent,
    DashboardHeader,
    HomeFooter,
    FlowMenuRegister,
    HeaderUserInfo,
  },
  data() {
    return {
      show: true,
      auth: "",
      rerender_headeruserinfo: true,
    };
  },
};
</script>

<style>
</style>