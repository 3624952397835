<template>
  <div class="homefooter">
    <div
      class="bg-white"
      style="
        padding-top: 20px;
        padding-left: 40px;
        padding-bottom: 40px;
        padding-right: 40px;
      "
    >
      <b-row>
        <b-col lg="3" md="3" sm="12" xs="12">
          <div class="logo-bottom-left">
            <img :alt="altlogo" :src="logo" height="70" />
          </div>
        </b-col>
        <b-col lg="6" md="6" sm="12" xs="12">
          <b-row class="mt-4">
            <b-col>
              <table border="0" width="100%">
                <tr>
                  <td class="text-center">
                    <img alt="Map" src="../assets/img/map.svg" width="50" />
                  </td>
                </tr>
                <tr>
                  <td style="line-height: 1" class="text-center">
                    <small class="text-success">
                      Visit Us<br />
                      <b>{{ themesapp.address }}</b>
                    </small>
                  </td>
                </tr>
              </table>
            </b-col>

            <b-col>
              <table border="0" width="100%">
                <tr>
                  <td class="text-center">
                    <img
                      alt="Telephone"
                      src="../assets/img/telephone.svg"
                      width="40"
                    />
                  </td>
                </tr>
                <tr>
                  <td style="line-height: 1" class="text-center">
                    <small class="text-success">
                      Telepon<br />
                      <b>{{ themesapp.phone }}</b>
                    </small>
                  </td>
                </tr>
              </table>
            </b-col>
            <b-col>
              <table border="0" width="100%">
                <tr>
                  <td width="50" class="text-center">
                    <img alt="email" src="../assets/img/email.svg" width="40" />
                  </td>
                </tr>
                <tr>
                  <td style="line-height: 1" class="text-center">
                    <small class="text-success">
                      Email<br />
                      <b>
                        {{ themesapp.email }}
                      </b>
                    </small>
                  </td>
                </tr>
              </table>
            </b-col>
          </b-row>
        </b-col>
        <b-col lg="3" md="3" sm="12" xs="12">
          <table border="0" width="100%">
            <tr>
              <td class="text-center" width="50%">
                <small class="text-success">Follow Us</small>
                <table width="100%" class="mt-2">
                  <tr>
                    <td class="text-right">
                      <a href="https://facebook.com/">
                        <img
                          alt="Facebook"
                          src="../assets/img/facebook.svg"
                          width="30"
                        />
                      </a>
                    </td>
                    <td class="text-left">
                      <a href="https://instagram.com/">
                        <img
                          alt="Instagram"
                          src="../assets/img/instagram.svg"
                          width="30"
                        />
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2">
                      <a href="https://youtube.com/">
                        <img
                          alt="Youtube"
                          src="../assets/img/youtube.svg"
                          width="70"
                        />
                      </a>
                    </td>
                  </tr>
                </table>
              </td>
              <td class="text-center">
                <div style="padding-top: 15px">
                  <small class="text-success">Designed by:</small>
                  <div style="margin-top: -20px">
                    <img
                      alt="Zcoms Tech"
                      src="../assets/img/zcoms-tech.png"
                      class="img-fluid"
                    />
                  </div>
                </div>
              </td>
            </tr>
          </table>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "HomeFooter",
  data() {
    return {
      themesapp: [],
      basePath: axios.defaults.baseURL,
      logo: "",
      altlogo: "",
    };
  },
  created() {
    this.defaultVar();
  },
  methods: {
    defaultVar() {
      this.themesapp = this.$store.getters.themesapp;
      this.altlogo = this.themesapp.name;
      this.logo = this.basePath + "/uploads/settingapps/" + this.themesapp.logo;
    },
  },
};
</script>

<style>
</style>