<template>
  <div>
    <div class="reginfo2">
      <div class="infotitle">
        <b-container>
          <b-row>
            <b-col cols="1"> </b-col>
            <b-col cols="11" class="text-left">
              <h4>KETERANGAN TEMPAT TINGGAL</h4>
            </b-col>
          </b-row>
        </b-container>
      </div>
      <form @submit.prevent="handleSubmit">
        <input type="hidden" v-model="form.id" />
        <input type="hidden" v-model="form.id_reg" />
        <input type="hidden" v-model="form.id_user" />

        <div class="infocontent2">
          <div style="max-width: 900px; margin: auto">
            <div class="mb-3 row form-input-label-right">
              <label
                for="inputText08"
                class="col-md-2 col-form-label color-tosca"
                ><b>Tinggal dengan</b></label
              >
              <div class="col-md-10">
                <select
                  v-model="form.id_family"
                  class="form-control form-control-lg"
                  @change="handleSelectParent"
                >
                  <option value="0">IBU & AYAH</option>
                  <option
                    v-for="select in infamilies"
                    v-bind:key="select.value"
                    v-bind:value="select.value"
                  >
                    {{ select.text }}
                  </option>
                </select>
              </div>
            </div>
            <b-overlay
              :show="loaderShow"
              spinner-variant="primary"
              spinner-type="grow"
              spinner-small
              rounded="sm"
            >
              <b-card :aria-hidden="loaderShow ? 'true' : null">
                <div class="mb-3 row form-input-label-right">
                  <label
                    for="inputAddress"
                    class="col-md-2 col-form-label color-tosca"
                    ><b>Alamat</b></label
                  >
                  <div class="col-md-10">
                    <textarea
                      class="form-control form-control-lg"
                      v-model="form.address"
                      name="address"
                      rows="3"
                      id="inputAddress"
                      required
                      disabled
                    ></textarea>
                  </div>
                </div>
                <div class="mb-3 row form-input-label-right">
                  <label class="col-md-2 col-form-label color-tosca"
                    ><b>Provinsi</b></label
                  >
                  <div class="col-md-10">
                    <select
                      v-model="form.id_province"
                      class="form-control form-control-lg"
                      @change="handleSelectCity"
                      disabled
                    >
                      <option
                        v-for="select in provinceselect"
                        v-bind:key="select.value"
                        v-bind:value="select.value"
                      >
                        {{ select.name }}
                      </option>
                    </select>
                  </div>
                </div>

                <div class="mb-3 row form-input-label-right">
                  <label class="col-md-2 col-form-label color-tosca"
                    ><b>Kota/<br />Kabupaten</b></label
                  >
                  <div class="col-md-10">
                    <select
                      v-model="form.id_city"
                      class="form-control form-control-lg"
                      @change="handleSelectDistrict"
                      disabled
                    >
                      <option
                        v-for="select in cityselect"
                        v-bind:key="select.value"
                        v-bind:value="select.value"
                      >
                        {{ select.name }}
                      </option>
                    </select>
                  </div>
                </div>

                <div class="mb-3 row form-input-label-right">
                  <label class="col-md-2 col-form-label color-tosca"
                    ><b>Kecamatan</b></label
                  >
                  <div class="col-md-10">
                    <select
                      v-model="form.id_district"
                      class="form-control form-control-lg"
                      @change="handleSelectSubDistrict"
                      disabled
                    >
                      <option
                        v-for="select in districtselect"
                        v-bind:key="select.value"
                        v-bind:value="select.value"
                      >
                        {{ select.name }}
                      </option>
                    </select>
                  </div>
                </div>

                <div class="mb-3 row form-input-label-right">
                  <label class="col-md-2 col-form-label color-tosca"
                    ><b>Kelurahan</b></label
                  >
                  <div class="col-md-10">
                    <select
                      v-model="form.id_subdistrict"
                      class="form-control form-control-lg"
                      disabled
                    >
                      <option
                        v-for="select in subdistrictselect"
                        v-bind:key="select.value"
                        v-bind:value="select.value"
                      >
                        {{ select.name }}
                      </option>
                    </select>
                  </div>
                </div>

                <div class="mb-3 row form-input-label-right">
                  <label
                    for="inputPostalcode"
                    class="col-md-2 col-form-label color-tosca"
                    ><b>Kodepos</b></label
                  >
                  <div class="col-md-10">
                    <input
                      v-model="form.postalcode"
                      name="postalcode"
                      type="text"
                      class="form-control form-control-lg"
                      id="inputPostalcode"
                      required
                      disabled
                    />
                  </div>
                </div>
              </b-card>
            </b-overlay>
            <br />
            <div class="mb-3 row form-input-label-right">
              <label
                for="inputHome_number"
                class="col-md-2 col-form-label color-tosca"
                ><b>Nomor Rumah</b></label
              >
              <div class="col-md-10">
                <input
                  v-model="form.home_number"
                  name="home_number"
                  type="text"
                  class="form-control form-control-lg"
                  id="inputHome_number"
                  required
                />
              </div>
            </div>

            <div class="mb-3 row form-input-label-right">
              <label class="col-md-2 col-form-label color-tosca"
                ><b>RT/RW</b></label
              >
              <b-row>
                <b-col>
                  <div class="col-sm-12">
                    <input
                      v-model="form.rt_number"
                      name="rt_number"
                      type="text"
                      class="form-control form-control-lg"
                      placeholder="RT"
                      required
                    />
                  </div>
                </b-col>
                <b-col>
                  <div class="col-sm-12">
                    <input
                      v-model="form.rw_number"
                      name="rw_number"
                      type="text"
                      class="form-control form-control-lg"
                      placeholder="RW"
                      required
                    />
                  </div>
                </b-col>
              </b-row>
            </div>

            <div class="mb-3 row form-input-label-right">
              <label
                for="inputCellphone"
                class="col-md-2 col-form-label color-tosca"
                ><b>No Handphone</b></label
              >
              <div class="col-md-10">
                <input
                  v-model="form.cellphone"
                  name="cellphone"
                  type="text"
                  class="form-control form-control-lg"
                  id="inputCellphone"
                  required
                />
              </div>
            </div>
          </div>
        </div>

        <div class="infotitle">
          <b-container>
            <b-row>
              <b-col cols="1"> </b-col>
              <b-col cols="11" class="text-left">
                <h4>JARAK DARI RUMAH KE SEKOLAH</h4>
              </b-col>
            </b-row>
          </b-container>
        </div>

        <div class="infocontent2">
          <div style="max-width: 900px; margin: auto">
            <div class="mb-3 row form-input-label-right">
              <label
                for="inputText08"
                class="col-md-2 col-form-label color-tosca"
                ><b>Moda Transportasi</b></label
              >
              <div class="col-md-10">
                <select
                  v-model="form.id_modatransportation"
                  class="form-control form-control-lg"
                >
                  <option
                    v-for="select in modatransportationselect"
                    v-bind:key="select.value"
                    v-bind:value="select.value"
                  >
                    {{ select.name }}
                  </option>
                </select>
              </div>
            </div>
            <div class="mb-3 row form-input-label-right">
              <label
                for="inputSchoolhomedistance"
                class="col-md-2 col-form-label color-tosca"
                ><b>Jarak Rumah ke Sekolah (Km)</b></label
              >
              <div class="col-md-10">
                <div class="row g-2 align-items-center">
                  <div class="col-auto">
                    <input
                      type="number"
                      min="0"
                      max="50"
                      class="form-control form-control-lg"
                      v-model="form.distance_km"
                      style="max-width: 150px"
                    />
                  </div>
                  <div class="col-auto">
                    <span id="passwordHelpInline" class="form-text text-muted">
                      Km
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div class="mb-3 row form-input-label-right">
              <label
                for="inputSchoolhomedistance"
                class="col-md-2 col-form-label color-tosca"
                ><b>Waktu Tempuh (Jam)</b></label
              >
              <div class="col-md-10">
                <div class="row g-2 align-items-center">
                  <div class="col-auto">
                    <input
                      type="number"
                      min="0"
                      max="50"
                      class="form-control form-control-lg"
                      v-model="form.distance_hour"
                    />
                  </div>
                  <div class="col-auto">
                    <span id="passwordHelpInline" class="form-text text-muted">
                      Jam
                    </span>
                  </div>
                  <div class="col-auto">
                    <input
                      type="number"
                      min="0"
                      max="59"
                      class="form-control form-control-lg"
                      v-model="form.distance_minute"
                    />
                  </div>
                  <div class="col-auto">
                    <span id="passwordHelpInline" class="form-text text-muted">
                      Menit
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div v-if="addinfo">
              <div class="mb-3 row form-input-label-right">
                <label
                  for="inputSchoolhomedistance"
                  class="col-md-2 col-form-label color-tosca"
                  ><b>Jarak Rumah</b></label
                >
                <div class="col-md-5">
                  <select
                    v-model="form.schoolhomedistance"
                    class="form-control form-control-lg"
                  >
                    <option
                      v-for="select in distances"
                      v-bind:key="select.value"
                      v-bind:value="select.value"
                    >
                      {{ select.text }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="mb-3 row form-input-label-right">
                <label
                  for="inputTimedistance"
                  class="col-md-2 col-form-label color-tosca"
                  ><b>Waktu Tempuh</b></label
                >
                <div class="col-md-5">
                  <select
                    v-model="form.timedistance"
                    class="form-control form-control-lg"
                  >
                    <option
                      v-for="select in times"
                      v-bind:key="select.value"
                      v-bind:value="select.value"
                    >
                      {{ select.text }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>

          <div class="text-right">
            <button class="btn btn-info btn-lg" type="submit">
              {{ displayLoadingSubmit }}
            </button>
            <br />
            <br />
            <small
              ><i class="text-danger">{{ displayError }}</i></small
            >
            <i class="text-success">{{ displaySuccess }}</i>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";

export default {
  name: "LivingProfileStudent",
  data() {
    return {
      auth: localStorage.getItem("auth"),
      token: localStorage.getItem("token"),
      form: {
        id: "",
        id_user: "",
        id_reg: "",
        id_family: "",
        address: "",
        id_province: "",
        id_city: "",
        id_district: "",
        id_subdistrict: "",
        home_number: "",
        rt_number: "",
        rw_number: "",
        postalcode: "",
        id_modatransportation: "",
        schoolhomedistance: "",
        timedistance: "",
        distance_km: 1,
        distance_hour: 0,
        distance_minute: 30,
        cellphone: "",
      },
      distances: [
        {
          value: "< 1 km",
          text: "< 1 km",
        },
        {
          value: "1 km sd 3 km",
          text: "1 km sd 3 km",
        },
        {
          value: "3 km sd 5 km",
          text: "3 km sd 5 km",
        },
        {
          value: "> 5 km",
          text: "> 5 km",
        },
      ],
      times: [
        {
          value: "< 5 menit",
          text: "< 5 menit",
        },
        {
          value: "5 menit sd 30 menit",
          text: "5 menit sd 30 menit",
        },
        {
          value: "30 menit sd 1 jam",
          text: "30 menit sd 1 jam",
        },
        {
          value: "1 jam sd 2 jam",
          text: "1 jam sd 2 jam",
        },
        {
          value: "> 2 jam",
          text: "> 2 jam",
        },
      ],

      provinceselect: [],
      cityselect: [],
      districtselect: [],
      subdistrictselect: [],
      modatransportationselect: [],

      infamilies: [],
      livinginfo: [],
      displayLoadingSubmit: "Simpan Data",
      displayError: "",
      displaySuccess: "",

      addinfo: false,

      loaderShow: false,
    };
  },
  created() {
    this.defaultVar();
    this.getLivingInfo();
    this.getInFamily();
    this.getProvinceSelect();
    this.getModaTransportationSelect();
  },
  methods: {
    defaultVar() {
      this.dataAuth = JSON.parse(this.auth);
      this.form.id_user = this.dataAuth.id_user;
      this.form.id_reg = this.dataAuth.id_reg;
    },
    async getProvinceSelect() {
      try {
        const response = await axios.get("api/province/select", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        this.provinceselect = response.data;
      } catch (error) {
        console.log(error);
      }
    },
    async getCitySelect(id_province) {
      try {
        const response = await axios.get("api/city/select/" + id_province, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        this.cityselect = response.data;
      } catch (error) {
        console.log(error);
      }
    },
    async getDistrictSelect(id_city) {
      this.loadingModal = true;
      try {
        const response = await axios.get("api/district/select/" + id_city, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        this.districtselect = response.data;

        this.loadingModal = false;
      } catch (error) {
        console.log(error);
        this.loadingModal = false;
      }
    },
    async getSubDistrictSelect(id_district) {
      this.loadingModal = true;
      try {
        const response = await axios.get(
          "api/subdistrict/select/" + id_district,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        );
        this.subdistrictselect = response.data;

        this.loadingModal = false;
      } catch (error) {
        console.log(error);
        this.loadingModal = false;
      }
    },
    handleSelectParent() {
      this.getFamily();
    },
    async getFamily() {
      try {
        const response = await axios({
          method: "post",
          url: "/api/getfamily/" + this.form.id_family,
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          data: {
            id_reg: this.form.id_reg,
          },
        });

        this.family = response.data;
        this.form.address = this.family.address;

        this.form.id_province = this.family.id_province;
        this.form.id_city = this.family.id_city;
        this.form.id_district = this.family.id_district;
        this.form.id_subdistrict = this.family.id_subdistrict;
        this.getCitySelect(this.family.id_province);
        this.getDistrictSelect(this.family.id_city);
        this.getSubDistrictSelect(this.family.id_district);
        this.form.postalcode = this.family.postalcode;
      } catch (error) {
        console.log(error);
      }
    },
    async getModaTransportationSelect() {
      try {
        const response = await axios.get("api/modatransportation/select", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        this.modatransportationselect = response.data.select;
        //console.log(this.modatransportationselect)
      } catch (error) {
        console.log(error);
      }
    },
    async getLivingInfo() {
      try {
        const response = await axios.get(
          "/api/livinginfobyreg/" + this.form.id_reg,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        );
        this.livinginfo = response.data;
        //console.log(this.livinginfo);
        //console.log(response.data.nisn);

        this.form.id = response.data.id;
        this.form.id_reg = response.data.id_reg;
        this.form.id_user = response.data.id_user;

        this.form.address = response.data.address;
        this.form.id_province = response.data.id_province;
        this.form.id_city = response.data.id_city;
        this.form.id_district = response.data.id_district;
        this.form.id_subdistrict = response.data.id_subdistrict;
        this.form.postalcode = response.data.postalcode;

        // check address, id_province, id_city, id_district, id_subdistrict, postalcode before selected id_family
        // if there is empty (address, id_province, id_city, id_district, id_subdistrict, postalcode) => id_family is null
        if (
          this.form.id_province == null ||
          this.form.id_city == null ||
          this.form.id_district == null ||
          this.form.id_subdistrict == null ||
          this.form.postalcode == null
        ) {
          this.form.id_family = "";
        } else {
          if (response.data.id_family == null) {
            this.form.id_family = 0;
          } else {
            this.form.id_family = response.data.id_family;
          }
        }

        this.form.home_number = response.data.home_number;
        this.form.rt_number = response.data.rt_number;
        this.form.rw_number = response.data.rw_number;
        this.form.id_modatransportation = response.data.id_modatransportation;
        this.form.schoolhomedistance = response.data.schoolhomedistance;
        this.form.timedistance = response.data.timedistance;
        this.form.distance_km = response.data.distance_km;
        this.form.distance_hour = response.data.distance_hour;
        this.form.distance_minute = response.data.distance_minute;
        this.form.cellphone = response.data.cellphone;

        this.getCitySelect(this.form.id_province);
        this.getDistrictSelect(this.form.id_city);
        this.getSubDistrictSelect(this.form.id_district);

        this.loading_livinginfo = "";
      } catch (error) {
        console.log(error);
      }
    },
    async getInFamily() {
      try {
        const response = await axios({
          method: "get",
          url: "/api/select_infamily/" + this.form.id_reg,
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        });

        this.infamilies = response.data;
        //console.log(this.infamilies);
        //console.log(this.ppdbs);
      } catch (error) {
        console.log(error);
      }
    },
    async handleSubmit() {
      this.displayLoadingSubmit = "processing...";
      try {
        const response = await axios({
          method: "post",
          url: "/api/update_livinginfo/" + this.form.id_reg,
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          data: this.form,
        });
        this.dataupdate = response.data;
        this.displayLoadingSubmit = "Simpan Data";
        this.displaySuccess = this.dataupdate.message;
        if (response.data.status == "error") {
          Swal.fire(
            "Warning!",
            "Mohon lengkapi data yang masih kosong.",
            "warning"
          );
        } else {
          Swal.fire(
            "Good job!",
            "Data berhasil disimpan!<br />Lanjut ke pengisian <b>Data Kesehatan</b>",
            "success"
          );

          this.$emit("rerender_headeruserinfo", false);
          setTimeout(() => {
            this.$emit("rerender_headeruserinfo", true);
            // rediret to next form
            this.$router.push({ path: "/medicalinfo" });
          }, 2000);
        }
      } catch (error) {
        console.log(error);
        this.displayError = error;
        this.displayLoadingSubmit = "Simpan Data";
        setTimeout(() => {
          this.displayError = "";
        }, 5000);
      }
    },
    makeToast(variant = null, setTitle, setBody) {
      this.$bvToast.toast(setBody, {
        title: setTitle,
        variant: variant,
        solid: true,
      });
    },
    handleSelectCity() {
      this.getCitySelect(this.form.id_province);
    },
    handleSelectDistrict() {
      this.getDistrictSelect(this.form.id_city);
    },
    handleSelectSubDistrict() {
      this.getSubDistrictSelect(this.form.id_district);
    },
  },
};
</script>

<style>
</style>