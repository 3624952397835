<template>
  <div class="banner-headline">
    <div class="banner-headline-content">
      <h4 class="text-white text-center">
        FORMULIR PENDAFTARAN ORANG TUA/ WALI SISWA
      </h4>
      <div class="reg-container mt-4">
        <div class="reginfo2">
          <form @submit.prevent="handleSubmit">
            <div class="infotitle">
              <b-container>
                <b-row>
                  <b-col cols="1"> </b-col>
                  <b-col cols="11" class="text-left">
                    <h4>Akun Login Orang Tua/Wali</h4>
                  </b-col>
                </b-row>
              </b-container>
            </div>

            <div class="infocontent2">
              <div style="max-width: 900px; margin: auto">
                <div class="mb-3 row form-input-label-right">
                  <label
                    for="inputemail"
                    class="col-md-2 col-form-label color-tosca"
                    ><b>Email</b></label
                  >
                  <div class="col-md-10">
                    <input
                      v-model="form.email"
                      name="email"
                      type="email"
                      class="form-control form-control-lg"
                      id="inputemail"
                      @blur="checkEmailExist"
                      required
                    />
                    <div class="text-left">
                      <small class="text-danger"
                        ><i>{{ errorEmail }}</i></small
                      >
                    </div>
                  </div>
                </div>

                <div class="mb-3 row form-input-label-right">
                  <label
                    for="inputpassword"
                    class="col-md-2 col-form-label color-tosca"
                    ><b>Password</b></label
                  >
                  <div class="col-md-10">
                    <input
                      v-model="form.password"
                      name="password"
                      type="password"
                      class="form-control form-control-lg"
                      id="inputpassword"
                      required
                    />
                  </div>
                </div>

                <div class="mb-3 row form-input-label-right">
                  <label
                    for="inputcpassword"
                    class="col-md-2 col-form-label color-tosca"
                    ><b>Confirm Password</b></label
                  >
                  <div class="col-md-10">
                    <input
                      v-model="form.cpassword"
                      name="cpassword"
                      type="password"
                      class="form-control form-control-lg"
                      id="inputcpassword"
                      v-on:keyup="checkPassword"
                      required
                    />
                    <div class="text-left">
                      <small class="text-danger"
                        ><i>{{ errorPassword }}</i></small
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="infotitle">
              <b-container>
                <b-row>
                  <b-col cols="1"> </b-col>
                  <b-col cols="11" class="text-left">
                    <h4>Profile Singkat Pendaftaran (Orang Tua/Wali)</h4>
                  </b-col>
                </b-row>
              </b-container>
            </div>

            <div class="infocontent2">
              <div style="max-width: 900px; margin: auto">
                <div class="mb-3 row form-input-label-right">
                  <label
                    for="inputpg_fullname"
                    class="col-md-2 col-form-label color-tosca"
                    ><b>Nama Pendaftar</b></label
                  >
                  <div class="col-md-10">
                    <input
                      v-model="form.fullname"
                      name="fullname"
                      type="text"
                      class="form-control form-control-lg"
                      id="inputpg_fullname"
                      required
                    />
                  </div>
                </div>

                <div class="mb-3 row form-input-label-right">
                  <label
                    for="inputTextB01"
                    class="col-md-2 col-form-label color-tosca"
                    ><b>Hubungan Pendaftar</b></label
                  >
                  <div class="col-md-10">
                    <b-form-select
                      v-model="form.id_relation"
                      name="id_relation"
                      size="lg"
                      :options="relations"
                      required
                    ></b-form-select>
                    <small
                      ><i class="text-muted">{{ loading_relations }}</i></small
                    >
                  </div>
                </div>

                <div class="mb-3 row form-input-label-right">
                  <label
                    for="inputbirthplace"
                    class="col-md-2 col-form-label color-tosca"
                    ><b>Tempat Lahir</b></label
                  >
                  <div class="col-md-10">
                    <input
                      type="text"
                      v-model="form.birthplace"
                      name="birthplace"
                      class="form-control form-control-lg"
                      id="inputbirthplace"
                      required
                    />
                  </div>
                </div>

                <div class="mb-3 row form-input-label-right">
                  <label
                    for="inputbirthdate"
                    class="col-md-2 col-form-label color-tosca"
                    ><b>Tanggal Lahir</b></label
                  >
                  <div class="col-md-5">
                    <div class="input-group">
                      <flat-pickr
                        v-model="form.birthdate"
                        :config="config"
                        class="form-control form-control-lg"
                        placeholder="Select date"
                        name="date"
                      >
                      </flat-pickr>
                      <div class="input-group-btn">
                        <button
                          class="btn btn-lg btn-dark"
                          type="button"
                          title="Toggle"
                          data-toggle
                        >
                          <b-icon icon="calendar3"></b-icon>
                        </button>
                      </div>
                    </div>
                    <!--
                    <b-form-datepicker
                      id="inputbirthdate"
                      v-model="form.birthdate"
                      class="mb-2"
                      size="lg"
                      required
                    ></b-form-datepicker>
                    -->
                  </div>
                </div>

                <div class="mb-3 row form-input-label-right">
                  <label
                    for="inputWni"
                    class="col-md-2 col-form-label color-tosca"
                    ><b>Kewarganegaraan</b></label
                  >
                  <div class="col-md-10">
                    <b-form-select
                      v-model="form.wni"
                      name="wni"
                      size="lg"
                      :options="wnis"
                      required
                    ></b-form-select>
                  </div>
                </div>

                <div class="mb-3 row form-input-label-right">
                  <label
                    for="inputReligion"
                    class="col-md-2 col-form-label color-tosca"
                    ><b>Agama</b></label
                  >
                  <div class="col-md-10">
                    <b-form-select
                      v-model="form.religion"
                      name="religion"
                      size="lg"
                      :options="religions"
                      required
                    ></b-form-select>
                  </div>
                </div>

                <div class="mb-3 row form-input-label-right">
                  <label
                    for="inputEdulevel"
                    class="col-md-2 col-form-label color-tosca"
                    ><b>Pendidikan</b></label
                  >
                  <div class="col-md-10">
                    <b-form-select
                      v-model="form.edulevel"
                      name="edulevel"
                      size="lg"
                      :options="edulevels"
                      required
                    ></b-form-select>
                  </div>
                </div>

                <div class="mb-3 row form-input-label-right">
                  <label
                    for="inputTextB01"
                    class="col-md-2 col-form-label color-tosca"
                    ><b>Tipe Pekerjaan</b></label
                  >
                  <div class="col-md-10">
                    <b-form-select
                      v-model="form.id_work"
                      name="id_work"
                      size="lg"
                      :options="works"
                      required
                    ></b-form-select>
                    <small
                      ><i class="text-muted">{{ loading_works }}</i></small
                    >
                  </div>
                </div>

                <div class="mb-3 row form-input-label-right">
                  <label
                    for="inputaddress"
                    class="col-md-2 col-form-label color-tosca"
                    ><b>Alamat</b></label
                  >
                  <div class="col-md-10">
                    <textarea
                      id="inputaddress"
                      class="form-control form-control-lg"
                      v-model="form.address"
                      name="address"
                      rows="3"
                      required
                    ></textarea>
                  </div>
                </div>

                <div class="mb-3 row form-input-label-right">
                  <label class="col-md-2 col-form-label color-tosca"
                    ><b>Provinsi</b></label
                  >
                  <div class="col-md-10">
                    <select
                      v-model="form.id_province"
                      class="form-control form-control-lg"
                      @change="handleSelectCity"
                    >
                      <option
                        v-for="select in provinceselect"
                        v-bind:key="select.value"
                        v-bind:value="select.value"
                      >
                        {{ select.name }}
                      </option>
                    </select>
                  </div>
                </div>

                <div class="mb-3 row form-input-label-right">
                  <label class="col-md-2 col-form-label color-tosca"
                    ><b>Kota/Kabupaten</b></label
                  >
                  <div class="col-md-10">
                    <select
                      v-model="form.id_city"
                      class="form-control form-control-lg"
                      @change="handleSelectDistrict"
                    >
                      <option
                        v-for="select in cityselect"
                        v-bind:key="select.value"
                        v-bind:value="select.value"
                      >
                        {{ select.name }}
                      </option>
                    </select>
                  </div>
                </div>

                <div class="mb-3 row form-input-label-right">
                  <label class="col-md-2 col-form-label color-tosca"
                    ><b>Kecamatan</b></label
                  >
                  <div class="col-md-10">
                    <select v-model="form.id_district" class="form-control form-control-lg" @change="handleSelectSubDistrict">
                      <option
                        v-for="select in districtselect"
                        v-bind:key="select.value"
                        v-bind:value="select.value"
                      >
                        {{ select.name }}
                      </option>
                    </select>
                  </div>
                </div>

                <div class="mb-3 row form-input-label-right">
                  <label class="col-md-2 col-form-label color-tosca"
                    ><b>Kelurahan</b></label
                  >
                  <div class="col-md-10">
                    <select v-model="form.id_subdistrict" class="form-control form-control-lg">
                      <option
                        v-for="select in subdistrictselect"
                        v-bind:key="select.value"
                        v-bind:value="select.value"
                      >
                        {{ select.name }}
                      </option>
                    </select>
                  </div>
                </div>

                <div class="mb-3 row form-input-label-right">
                  <label
                    for="inputpg_cellphone"
                    class="col-md-2 col-form-label color-tosca"
                    ><b>Handphone</b></label
                  >
                  <div class="col-md-10">
                    <input
                      id="inputcellphone"
                      type="text"
                      v-model="form.cellphone"
                      name="cellphone"
                      class="form-control form-control-lg"
                      required
                    />
                  </div>
                </div>
              </div>
              <br />
              <div class="infotitle">
                <b-container>
                  <b-row>
                    <b-col cols="1"> </b-col>
                    <b-col cols="11" class="text-left">
                      <h4>Informasi Pendaftaran</h4>
                    </b-col>
                  </b-row>
                </b-container>
              </div>

              <div class="infocontent2">
                <div style="max-width: 900px; margin: auto">
                  <div class="mb-3 row form-input-label-right">
                    <label class="col-md-2 col-form-label color-tosca"
                      ><b>Sumber Informasi</b></label
                    >
                    <div class="col-md-10">
                      <select
                        v-model="form.id_infreg"
                        class="form-control form-control-lg"
                        @change="changeInfreg"
                        required
                      >
                        <option
                          v-for="infreg in infregs"
                          v-bind:key="infreg.id"
                          v-bind:value="infreg.id"
                          :data-addinfo="infreg.addinfo"
                        >
                          {{ infreg.name }}
                        </option>
                      </select>
                      <small
                        ><i class="text-muted">{{ loading_infregs }}</i></small
                      >
                    </div>
                  </div>
                  <div v-if="showInfreglink">
                    <div class="mb-3 row form-input-label-right">
                      <label
                        for="infreglink_fullname"
                        class="col-md-2 col-form-label color-tosca"
                        ><b>Nama Perekomendasi</b></label
                      >
                      <div class="col-md-10">
                        <input
                          v-model="form.infreglink_fullname"
                          type="text"
                          class="form-control form-control-lg"
                          id="infreglink_fullname"
                        />
                      </div>
                    </div>

                    <div class="mb-3 row form-input-label-right">
                      <label
                        for="infreglink_cellphone"
                        class="col-md-2 col-form-label color-tosca"
                        ><b>Telepon Perekomendasi</b></label
                      >
                      <div class="col-md-10">
                        <input
                          v-model="form.infreglink_cellphone"
                          type="text"
                          class="form-control form-control-lg"
                          id="infreglink_cellphone"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="text-right">
                <b-button
                  pill
                  variant="info"
                  type="submit"
                  size="lg"
                  style="padding-left: 30px; padding-right: 30px"
                  >{{ submitLabel }}</b-button
                >
                <br />
                <small
                  ><i class="text-danger">{{ isError }}</i></small
                >
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

export default {
  name: "RegisterForm",
  components: {
    flatPickr,
  },
  data() {
    return {
      config: {
        wrap: true, // set wrap to true only when using 'input-group'
        altFormat: "M j, Y",
        altInput: true,
        dateFormat: "Y-m-d",
      },
      form: {
        email: "",
        password: "",
        cpassword: "",
        fullname: "",
        birthplace: "",
        birthdate: "",
        address: "",
        id_province: "",
        id_city: "",
        id_district: "",
        id_subdistrict: "",
        cellphone: "",
        id_relation: "",
        id_work: "",
        wni: "",
        religion: "",
        edulevel: "",
        id_infreg: "",
        infreglink_fullname: "",
        infreglink_cellphone: "",
      },
      isError: "",
      errorEmail: "",
      errorPassword: "",
      pg_address: "",
      loading_relations: "",
      loading_works: "",
      loading_infregs: "",
      showInfreglink: false,
      addinfo: "",
      provinceselect: [],
      cityselect: [],
      districtselect: [],
      subdistrictselect: [],
      infregs: [],
      works: [],
      relations: [],
      wnis: [
        {
          value: "WNI",
          text: "Warga Negara Indonesia (WNI)",
        },
        {
          value: "WNA",
          text: "Warga Negara Asing (WNA)",
        },
      ],
      religions: [
        {
          value: "Islam",
          text: "Islam",
        },
        {
          value: "Kristen",
          text: "Kristen",
        },
        {
          value: "Katolik",
          text: "Katolik",
        },
        {
          value: "Hindu",
          text: "Hindu",
        },
        {
          value: "Budha",
          text: "Budha",
        },
        {
          value: "Konghuchu",
          text: "Konghuchu",
        },
      ],
      edulevels: [
        {
          value: "SD",
          text: "Sekolah Dasar (SD)",
        },
        {
          value: "SMP",
          text: "Sekolah Menengah Pertama (SMP)",
        },
        {
          value: "SMA",
          text: "Sekolah Menengah Atas (SMA)",
        },
        {
          value: "S1",
          text: "Sarjana (S1)",
        },
        {
          value: "S2",
          text: "Sarjana (S2)",
        },
        {
          value: "S3",
          text: "Sarjana (S3)",
        },
      ],
      submitLabel: "Daftar",
      show: true,
    };
  },
  created() {
    this.getRelations();
    this.getWorks();
    this.getInfreg();
    this.getProvinceSelect();
  },
  methods: {
    async getProvinceSelect() {
      try {
        const response = await axios.get("api/province/select", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        this.provinceselect = response.data;
      } catch (error) {
        console.log(error);
      }
    },
    async getCitySelect(id_province) {
        this.loadingModal = true;
      try {
        const response = await axios.get("api/city/select/"+id_province, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        this.cityselect = response.data;
        this.loadingModal = false;
      } catch (error) {
        console.log(error);
        this.loadingModal = false;
      }
    },
    async getDistrictSelect(id_city) {
        this.loadingModal = true;
      try {
        const response = await axios.get("api/district/select/"+id_city, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        this.districtselect = response.data;
        
        this.loadingModal = false;
      } catch (error) {
        console.log(error);
        this.loadingModal = false;
      }
    },
    async getSubDistrictSelect(id_district) {
        this.loadingModal = true;
      try {
        const response = await axios.get("api/subdistrict/select/"+id_district, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        this.subdistrictselect = response.data;
        
        this.loadingModal = false;
      } catch (error) {
        console.log(error);
        this.loadingModal = false;
      }
    },
    async getRelations() {
      try {
        const response = await axios.get("/api/select_relations");
        this.relations = response.data;
        //console.log(this.relations);

        this.loading_relations = "";
      } catch (error) {
        console.log(error);
      }
    },
    async getWorks() {
      try {
        const response = await axios.get("/api/select_works");
        this.works = response.data;
        //console.log(this.relations);

        this.loading_works = "";
      } catch (error) {
        console.log(error);
      }
    },
    async getInfreg() {
      try {
        const response = await axios.get("/api/infregs");
        this.infregs = response.data;
        //console.log(this.relations);

        this.loading_infregs = "";
      } catch (error) {
        console.log(error);
      }
    },
    changeInfreg(event) {
      var options = event.target.options;
      if (options.selectedIndex > -1) {
        this.addinfo =
          options[options.selectedIndex].getAttribute("data-addinfo");
        if (this.addinfo == 1) {
          this.showInfreglink = true;
        } else {
          this.showInfreglink = false;
          this.form.infreglink_fullname = "";
          this.form.infreglink_cellphone = "";
        }
      }
    },
    async handleSubmit(event) {
      event.preventDefault();
      this.submitLabel = "process..";
      //alert(JSON.stringify(this.form));
      try {
        const response = await axios.post("/api/pgregister", this.form);

        //console.log(response);
        // save to localStorage
        let dataAuth = JSON.stringify(response.data.auth);
        let dataPayload = JSON.stringify(response.data.payload);
        localStorage.setItem("token", response.data.access_token);
        localStorage.setItem("auth", dataAuth);
        localStorage.setItem("payload", dataPayload);
        let role = response.data.auth.role;
        //console.log(role);
        // save to Vuex
        let dataVuex = {
          token: response.data.access_token,
          auth: dataAuth,
          payload: dataPayload,
        };
        this.$store.commit("login", dataVuex);

        // redirect
        if (role == "Parentguard") {
          this.$router.push({ path: "/reglist" });
        } else if (role == "Student") {
          this.$router.push({ path: "/dashboard" });
        }
      } catch (error) {
        console.log(error);
        this.submitLabel = "Daftar";
        this.isError = error.response.data.message;
        setTimeout(() => {
          this.isError = "";
        }, 5000);
      }
    },
    async checkEmailExist() {
      try {
        const response = await axios({
          method: "post",
          url: "/api/checkemailexist",
          data: {
            email: this.form.email,
          },
        });
        let exist = response.data.exist;
        if (exist == true) {
          this.errorEmail = response.data.message;
        } else {
          this.errorEmail = "";
        }
      } catch (error) {
        console.log(error);
      }
    },
    checkPassword() {
      if (this.form.password != this.form.cpassword) {
        this.errorPassword = "Password belum cocok..";
      } else {
        this.errorPassword = "";
      }
    },
    handleSelectCity() {
      this.getCitySelect(this.form.id_province);
    },
    handleSelectDistrict() {
      this.getDistrictSelect(this.form.id_city);
    },
    handleSelectSubDistrict() {
      this.getSubDistrictSelect(this.form.id_district);
    },
  },
};
</script>

<style>
</style>