<template>
  <div>
    <b-container style="max-width: 1400px">
      <DashboardHeader />
      <div class="banner-headline text-center">
        <div class="banner-headline-content">
          <h4 class="text-white text-center">
            SELAMAT DATANG, <i>{{ fullname }}</i
            ><br />
            <small>FORMULIR PENDAFTARAN SISWA</small><br />
            <p class="lead" v-if="showRegListInfo">
              <small
                >Anda sudah mendaftarkan: (<i>{{ displayRegList }}</i
                >) |
                <b-link class="text-white" :to="{ path: '/reglist' }"
                  >Lihat disini</b-link
                ></small
              >
            </p>
          </h4>
          <div class="reg-container mt-4">
            <RegTab />
          </div>
        </div>
      </div>
      <HomeFooter />
    </b-container>
  </div>
</template>

<script>
import axios from "axios";

import DashboardHeader from "@/components/DashboardHeader.vue";
import HomeFooter from "@/components/HomeFooter.vue";
import RegTab from "@/components/RegTab.vue";

export default {
  name: "Parentguard",
  components: {
    DashboardHeader,
    HomeFooter,
    RegTab,
  },
  data() {
    return {
      token: localStorage.getItem("token"),
      auth: "",
      fullname: "",
      id_user: "",
      showRegListInfo: false,
      regs: "",
      displayRegList: "",
    };
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  created() {
    this.getAuth();
    this.getRegList();
  },
  methods: {
    getAuth() {
      this.token = localStorage.getItem("token");
      this.auth = JSON.parse(localStorage.getItem("auth"));
      this.fullname = this.auth.fullname;
      this.id_user = this.auth.id_user;
    },
    async getRegList() {
      try {
        const response = await axios({
          method: "get",
          url: "/api/getreglist",
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        });
        this.regs = response.data.regs;
        if (this.regs.length > 0) {
          this.showRegListInfo = true;
          this.displayRegList = this.regs.length + " Anak";
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style>
</style>