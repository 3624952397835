<template>
  <div>
    <b-card>
      <h5>{{ label }} ({{ type }})</h5>
      <p class="text-muted">{{ formatinfo }}<br />{{ sizeinfo }}</p>
      <div v-if="loadingFile" class="mt-3">
        <b-skeleton animation="fade" width="85%"></b-skeleton>
        <b-skeleton animation="fade" width="55%"></b-skeleton>
        <b-skeleton animation="fade" width="70%"></b-skeleton>
        <b-skeleton animation="fade" width="85%"></b-skeleton>
        <b-skeleton animation="fade" width="55%"></b-skeleton>
        <b-skeleton animation="fade" width="70%"></b-skeleton>
      </div>
      <div v-if="anyFile">
        <div v-if="accept == 'application/pdf'">
          <b-icon
            icon="file-earmark-check"
            variant="danger"
            style="width: 120px; height: 120px"
          ></b-icon
          ><br /><a :href="urlFile">{{ filename }}</a>
        </div>
        <div v-if="accept == 'image/jpeg, image/png, image/gif'">
          <b-img
            v-bind="mainProps"
            :src="urlFile"
            thumbnail
            rounded
            fluid
            alt="Photo Profile"
          ></b-img>
        </div>
      </div>
      <div v-if="noFile" class="text-center">
        <b-card
          :title="title_no_file"
          tag="article"
          style="max-width: 20rem"
          class="mb-2"
        >
          <b-card-text>
            <b-icon
              icon="exclamation-circle"
              style="width: 120px; height: 120px"
            ></b-icon>
          </b-card-text>
        </b-card>
      </div>
      <div class="form-group mt-3">
        <input
          type="file"
          @change="onFileSelected"
          class="form-control-file"
          :accept="accept"
        />
      </div>
      <!--<b-button variant="primary" @click="onUpload">Upload File</b-button>-->
      &nbsp;&nbsp;<small class="text-muted"
        ><i
          >{{ uploadProgressFile }}
          <span class="text-success">{{ checkListSuccess }}</span></i
        ></small
      >
    </b-card>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";

export default {
  name: "GenUploadRegDoc",
  props: {
    label: { required: true, type: String },
    formatinfo: { required: true, type: String },
    sizeinfo: { required: true, type: String },
    type: { required: true, type: String },
    accept: { required: true, type: String },
  },
  data() {
    return {
      token: "",
      dataAuth: "",
      id_reg: "",
      selectedFile: null,
      loadingFile: true,
      anyFile: false,
      noFile: false,
      uploadProgressFile: "",
      filename: "",
      urlFile: "",
      basePath: axios.defaults.baseURL,
      basePathFolderImage: "/uploads/regdocsupports/",
      pathfoldername: "",
      mainProps: { width: 300, class: "m1" },
      checkListSuccess: "",
      title_no_file: "No file upload",
    };
  },
  created() {
    this.getDefaultVar();
    this.getDataUpload();
  },
  methods: {
    getDefaultVar() {
      this.token = localStorage.getItem("token");
      this.dataAuth = JSON.parse(localStorage.getItem("auth"));
      this.id_reg = this.dataAuth.id_reg;
    },
    async getDataUpload() {
      try {
        const response = await axios({
          method: "get",
          url: "/api/getregdocsupport/" + this.id_reg,
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        });
        //console.log(response.data.regdocsupport);
        let typeRegDoc = this.type.toLowerCase();
        let regdocsupport = response.data.regdocsupport[typeRegDoc];
        let pathfoldername = response.data.regdocsupport.pathfolder;
        this.pathfoldername = pathfoldername;
        //console.log(pathfolder);

        if (regdocsupport != undefined) {
          this.loadingFile = false;
          this.anyFile = true;
          this.noFile = false;

          let filename = regdocsupport.filename;
          this.filename = filename;

          this.urlFile =
            this.basePath +
            this.basePathFolderImage +
            this.pathfoldername +
            "/" +
            filename;
          //console.log(this.urlFile)
        } else {
          this.loadingFile = false;
          this.anyFile = false;
          this.noFile = true;
        }

        //this.urlPhoto = this.basePath . basePathFolderImage . response.data.regdocsupport.photo.filename;
        //console.log(this.urlPhoto);
        //console.log(response.data.regdocsupport.photo.filename)
        //console.log(response.data.regdocsupport.photo);
        //console.log(axios.defaults.baseURL);
      } catch (error) {
        console.log(error);
      }
    },
    onFileSelected(event) {
      this.selectedFile = event.target.files[0];
      this.onUpload();
    },
    onUpload() {
      const fd = new FormData();
      fd.append("file_upload", this.selectedFile);
      fd.append("type", this.type);
      axios
        .post("/api/upload_regdocsupport/" + this.id_reg, fd, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          onUploadProgress: (uploadEvent) => {
            this.uploadProgressFile =
              Math.round((uploadEvent.loaded / uploadEvent.total) * 100) + "%";
            console.log("Upload Progress: " + this.uploadProgressFile);
          },
        })
        .then(
          (res) => {
            console.log(res);
            if (res.data.status == "success") {
              this.$emit("rerender_headeruserinfo", false);
              setTimeout(() => {
                this.$emit("rerender_headeruserinfo", true);
              }, 2000);
              
              Swal.fire("Good job!", "Data berhasil disimpan!", "success");
              this.checkListSuccess = "Successfully uploaded..";
              this.getDataUpload();
            } else {
              Swal.fire("Error!", res.data.message, "error");
            }
            //console.log(fd);
          },
          (err) => {
            console.log(err);
            Swal.fire("Error!", "Error upload!, " + err.message, "error");
          }
        );
    },
  },
};
</script>

<style>
</style>