<template>
  <div>
    <div class="reginfo2">
      <div class="infotitle">
        <b-container>
          <b-row>
            <b-col cols="1"> </b-col>
            <b-col cols="11" class="text-left">
              <h4>KETERANGAN KESEHATAN</h4>
            </b-col>
          </b-row>
        </b-container>
      </div>

      <form @submit.prevent="handleSubmit">
        <input type="hidden" v-model="form.id" />
        <input type="hidden" v-model="form.id_reg" />
        <input type="hidden" v-model="form.id_user" />

        <div class="infocontent2">
          <div class="mb-3 row form-input-label-right" v-if="inputapetite">
            <label
              for="inputapetite"
              class="col-md-2 col-form-label color-tosca"
              ><b>Selera Makan</b></label
            >
            <div class="col-md-10">
              <input
                v-model="form.apetite"
                type="text"
                class="form-control form-control-lg"
                size="lg"
                id="inputapetite"
              />

              <div class="form-text text-muted text-left">
                <i
                  >Mohon sebutkan jika memiliki pantangan pada jenis makanan
                  tertentu.</i
                >
              </div>
            </div>
          </div>

          <div class="mb-3 row form-input-label-right">
            <label for="inputText06" class="col-md-2 col-form-label color-tosca"
              ><b>Golongan Darah</b></label
            >
            <div class="col-md-10">
              <b-form-select
                v-model="form.blood_grp"
                size="lg"
                :options="selects.bloods"
              >
              </b-form-select>
            </div>
          </div>

          <div class="mb-3 row form-input-label-right">
            <label for="inputText06" class="col-md-2 col-form-label color-tosca"
              ><b>Riwayat Kelahiran</b></label
            >
            <div class="col-md-10">
              <b-form-select
                v-model="form.birth_histry"
                size="lg"
                :options="selects.birth_histrys"
              >
              </b-form-select>
            </div>
          </div>

          <!--
          <div class="mb-3 row form-input-label-right">
            <label
              for="inputSocialOpp"
              class="col-md-2 col-form-label color-tosca"
              ><b>Kesempatan Bergaul</b></label
            >
            <div class="col-md-10">
              <input
                v-model="form.social_opp"
                type="text"
                class="form-control form-control-lg"
                id="inputSocialOpp"
              />
            </div>
          </div>
          -->

          <div class="mb-3 row form-input-label-right">
            <label for="inputWeight" class="col-md-2 col-form-label color-tosca"
              ><b>Berat Badan</b></label
            >
            <div class="col-md-10">
              <div class="row g-3 align-items-center">
                <div class="col-auto">
                  <input
                    v-model="form.weight"
                    type="number"
                    min="10"
                    max="200"
                    class="form-control form-control-lg"
                    id="inputWeight"
                  />
                </div>
                <div class="col-auto">
                  <span id="passwordHelpInline" class="form-text text-muted">
                    Kg
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div class="mb-3 row form-input-label-right">
            <label for="inputHeight" class="col-md-2 col-form-label color-tosca"
              ><b>Tinggi Badan</b></label
            >
            <div class="col-md-10">
              <div class="row g-3 align-items-center">
                <div class="col-auto">
                  <input
                    v-model="form.height"
                    type="number"
                    min="50"
                    max="300"
                    class="form-control form-control-lg"
                  />
                </div>
                <div class="col-auto">
                  <span id="passwordHelpInline" class="form-text text-muted">
                    cm
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div class="mb-3 row form-input-label-right">
            <label
              for="inputDisability"
              class="col-md-2 col-form-label color-tosca"
              ><b>Kelainan Jasmani</b></label
            >
            <div class="col-md-10">
              <input
                v-model="form.disability"
                type="text"
                class="form-control form-control-lg"
                id="inputDisability"
              />
              <div class="form-text text-muted text-left">
                <i>Mohon sebutkan jika ada kelainan jasmani.</i>
              </div>
            </div>
          </div>

          <div class="mb-3 row form-input-label-right">
            <label
              for="inputSurgicalHistry"
              class="col-md-2 col-form-label color-tosca"
              ><b>Riwayat Operasi</b></label
            >
            <div class="col-md-10">
              <input
                v-model="form.surgical_histry"
                type="text"
                class="form-control form-control-lg"
                id="inputSurgicalHistry"
              />
              <div class="form-text text-muted text-left">
                <i>Mohon sebutkan jika ada riwayat Tindakan Operasi.</i>
              </div>
            </div>
          </div>

          <div class="mb-3 row form-input-label-right">
            <label
              for="inputDeseaseHistry"
              class="col-md-2 col-form-label color-tosca"
              ><b>Riwayat Penyakit</b></label
            >
            <div class="col-md-10">
              <input
                v-model="form.desease_histry"
                type="text"
                class="form-control form-control-lg"
                id="inputDeseaseHistry"
              />
              <div class="form-text text-muted text-left">
                <i>Penyakit berat yang pernah diderita.</i>
              </div>
            </div>
          </div>

          <div class="mb-3 row form-input-label-right">
            <label
              for="inputFoodAllergy"
              class="col-md-2 col-form-label color-tosca"
              ><b>Alergi Makanan</b></label
            >
            <div class="col-md-10">
              <input
                v-model="form.food_allergy"
                type="text"
                class="form-control form-control-lg"
                id="inputFoodAllergy"
              />
              <div class="form-text text-muted text-left">
                <i>Alergi makanan yang pernah diderita.</i>
              </div>
            </div>
          </div>

          <div class="mb-3 row form-input-label-right">
            <label for="inputText06" class="col-md-2 col-form-label color-tosca"
              ><b>Alergi Obat</b></label
            >
            <div class="col-md-10">
              <input
                v-model="form.medic_allergy"
                type="text"
                class="form-control form-control-lg"
                id="inputMedicAllergy"
              />
              <div class="form-text text-muted text-left">
                <i>Alergi obat yang pernah diderita.</i>
              </div>
            </div>
          </div>
          <br />
          <div v-if="inputhospitalhistory">
            <div class="infotitle">
              <b-container>
                <b-row>
                  <b-col cols="1"> </b-col>
                  <b-col cols="11" class="text-left">
                    <h4>RIWAYAT RUMAH SAKIT</h4>
                  </b-col>
                </b-row>
              </b-container>
            </div>
            <br />
            <div class="mb-3 row form-input-label-right">
              <label for="inputText06" class="col-md-2 col-form-label color-tosca"
                ><b>Riwayat Rumah Sakit</b></label
              >
              <div class="col-md-10">
                <select
                  v-model="form.id_hospital"
                  name="lang"
                  class="form-control form-control-lg"
                  aria-label="Default select example"
                  id="inputIdHospitals"
                >
                  <option
                    v-for="hospital in hospitals"
                    v-bind:key="hospital.value"
                    v-bind:value="hospital.value"
                  >
                    {{ hospital.text }}
                  </option>
                  <option value="">(tidak ada)</option>
                </select>
              </div>
            </div>

            <div class="mb-3 row form-input-label-right">
              <label
                for="inputnoreg_rs"
                class="col-md-2 col-form-label color-tosca"
                ><b>Nomor Rekam Medis Rumah Sakit</b></label
              >
              <div class="col-md-10">
                <input
                  v-model="form.noreg_rs"
                  type="text"
                  class="form-control form-control-lg"
                  id="inputnoreg_rs"
                />
                <div class="form-text text-muted text-left">
                  <i>Mohon sebutkan jika ada.</i>
                </div>
              </div>
            </div>

            <div class="mb-3 row form-input-label-right">
              <label for="inputdr_rs" class="col-md-2 col-form-label color-tosca"
                ><b>Nama Dokter yg Menangani (Rumah Sakit)</b></label
              >
              <div class="col-md-10">
                <input
                  v-model="form.dr_rs"
                  type="text"
                  class="form-control form-control-lg"
                  id="inputdr_rs"
                />
                <div class="form-text text-muted text-left">
                  <i>Mohon sebutkan jika ada.</i>
                </div>
              </div>
            </div>
          </div>
          <br />
          <div class="infotitle">
            <b-container>
              <b-row>
                <b-col cols="1"> </b-col>
                <b-col cols="11" class="text-left">
                  <h4>RIWAYAT TERAPI</h4>
                </b-col>
              </b-row>
            </b-container>
          </div>
          <br />
          <div class="mb-3 row form-input-label-right">
            <label
              for="inputTherapyHistry"
              class="col-md-2 col-form-label color-tosca"
              ><b>Nama Terapi</b></label
            >
            <div class="col-md-10">
              <input
                v-model="form.therapy_histry"
                type="text"
                class="form-control form-control-lg"
                id="inputTherapyHistry"
              />
              <div class="form-text text-muted text-left">
                <i>Mohon sebutkan jika ada atau sedang menjalani Terapi.</i>
              </div>
            </div>
          </div>

          <div class="mb-3 row form-input-label-right">
            <label
              for="inputtherapy_histry_year"
              class="col-md-2 col-form-label color-tosca"
              ><b>Tahun</b></label
            >
            <div class="col-md-10">
              <input
                v-model="form.therapy_histry_year"
                type="text"
                class="form-control form-control-lg"
                id="inputtherapy_histry_year"
              />
            </div>
          </div>

          <div class="mb-3 row form-input-label-right">
            <label
              for="inputtherapy_desc"
              class="col-md-2 col-form-label color-tosca"
              ><b>Keterangan</b></label
            >
            <div class="col-md-10">
              <textarea
                v-model="form.therapy_desc"
                class="form-control"
                rows="3"
              ></textarea>
              <div class="form-text text-muted text-left">
                <i>Mohon jenis Terapi yg sedang atau pernah dijalani.</i>
              </div>
            </div>
          </div>

          <div class="text-right">
            <button class="btn btn-info btn-lg" type="submit">
              {{ displayLoadingSubmit }}
            </button>
            <br />
            <br />
            <small
              ><i class="text-danger">{{ displayError }}</i></small
            >
            <i class="text-success">{{ displaySuccess }}</i>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";

export default {
  name: "MedicalProfileStudent",
  data() {
    return {
      token: localStorage.getItem("token"),
      auth: localStorage.getItem("auth"),
      dataAuth: "",
      inputapetite: false,
      inputhospitalhistory: false,
      form: {
        id: "",
        id_user: "",
        id_reg: "",
        id_hospital: "",
        apetite: "",
        blood_grp: "",
        birth_histry: "",
        social_opp: "",
        weight: "",
        height: "",
        disability: "",
        surgical_histry: "",
        desease_histry: "",
        food_allergy: "",
        medic_allergy: "",
        noreg_rs: "",
        dr_rs: "",
        therapy_histry: "",
        therapy_histry_year: "",
        therapy_desc: "",
      },
      selects: {
        bloods: [
          {
            value: "A",
            text: "A",
          },
          {
            value: "B",
            text: "B",
          },
          {
            value: "O",
            text: "O",
          },
          {
            value: "AB",
            text: "AB",
          },
        ],
        birth_histrys: [
          {
            value: "Normal",
            text: "Kelahiran Normal",
          },
          {
            value: "Caesar",
            text: "Operasi caesar",
          },
        ],
      },
      hospitals: [],
      medicalinfo: [],
      displayLoadingSubmit: "Simpan Data",
      displayError: "",
      displaySuccess: "",
    };
  },
  created() {
    this.getDefaultVar();
    this.getMedicalInfo();
    this.getHospitals();
  },
  methods: {
    getDefaultVar() {
      this.token = localStorage.getItem("token");
      this.dataAuth = JSON.parse(localStorage.getItem("auth"));
      this.form.id_user = this.dataAuth.id_user;
      this.form.id_reg = this.dataAuth.id_reg;
    },
    async getHospitals() {
      try {
        const response = await axios.get("/api/select_hospitals");
        this.hospitals = response.data;
        //console.log(this.ppdbs);
      } catch (error) {
        console.log(error);
      }
    },
    async getMedicalInfo() {
      try {
        const response = await axios.get(
          "/api/medicalinfobyreg/" + this.form.id_reg,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        );
        this.medicalinfo = response.data;

        this.form.id = response.data.id;
        this.form.id_hospital = response.data.id_hospital;
        this.form.apetite = response.data.apetite;
        this.form.blood_grp = response.data.blood_grp;
        this.form.birth_histry = response.data.birth_histry;
        this.form.social_opp = response.data.social_opp;
        this.form.weight = response.data.weight;
        this.form.height = response.data.height;
        this.form.disability = response.data.disability;
        this.form.surgical_histry = response.data.surgical_histry;
        this.form.desease_histry = response.data.desease_histry;
        this.form.food_allergy = response.data.food_allergy;
        this.form.medic_allergy = response.data.medic_allergy;
        this.form.noreg_rs = response.data.noreg_rs;
        this.form.dr_rs = response.data.dr_rs;
        this.form.therapy_histry = response.data.therapy_histry;
        this.form.therapy_histry_year = response.data.therapy_histry_year;
        this.form.therapy_desc = response.data.therapy_desc;
      } catch (error) {
        console.log(error);
      }
    },
    async handleSubmit() {
      this.displayLoadingSubmit = "processing...";
      try {
        const response = await axios({
          method: "post",
          url: "/api/update_medicalinfo/" + this.form.id_reg,
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          data: this.form,
        });
        this.dataupdate = response.data;
        this.displayLoadingSubmit = "Simpan Data";
        Swal.fire("Good job!", "Data berhasil disimpan!", "success");
        // rediret to next form
        //this.$router.push({ path: "/achievementinfo" });
      } catch (error) {
        console.log(error);
        this.displayError = error.response.data.message;
        this.displayLoadingSubmit = "Simpan Data";
        setTimeout(() => {
          this.displayError = "";
        }, 5000);
      }
    },
    makeToast(variant = null, setTitle, setBody) {
      this.$bvToast.toast(setBody, {
        title: setTitle,
        variant: variant,
        solid: true,
      });
    },
  },
};
</script>

<style>
</style>